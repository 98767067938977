import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

BackgroundImage.propTypes = {
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

export const backgroundImageStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  pointerEvents: 'none',
};

export default function BackgroundImage({ image, alt, styles = {} }) {
  return (
    <GatsbyImage
      style={{ ...backgroundImageStyle, ...styles }}
      objectFit="cover"
      objectPosition="50% 50%"
      {...{ image, alt }}
    />
  );
}
