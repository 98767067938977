import styled from 'styled-components';
import Img from 'gatsby-image';

import { colors, mq, zIndex } from '../consts/style';

export const Container = styled.div`
  padding: 0 6.4rem;
  width: 100%;
  ${mq.large`padding: 0 4rem;`}
  ${mq.medium`padding: 0 4rem;`}
  ${mq.small`padding: 0 2rem;`}
`;

export const Inner = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 115.2rem;
  display: flex;

  ${mq.large`max-width: 76.8rem`}
`;

export const LegalContentContainer = styled(Container)`
  margin-bottom: 12.8rem;
  ${(props) => props.theme.mq.medium`
    margin-bottom: 9.6rem;
  `};
  ${Inner} {
    flex-direction: column;
    max-width: 72rem;
  }
  .content {
    width: 100%;
    margin: 6rem 0 0 0;
    font-size: 2.4rem;
    line-height: 2.6rem;
    * {
      margin-bottom: 2rem;
    }
    a {
      color: ${(props) => props.theme.colors.purple.primary};
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 1.2rem;
    }
  }
`;

/**
 * :(
 * Prismic still uses Gatsby V2 `fixed` and `fluid` fragments,
 * so it cant use the dedicated component in ./BackgroundImage
 */
export const PrismicBackgroundImage = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export const GenericPageWrapper = styled.div`
  background: ${colors.elements.background};
  z-index: ${zIndex.body};
  position: relative;
  padding-top: 19rem;
  ${mq.medium`padding-top: 13rem;`}
`;
