import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import { font, zIndex } from '../consts/style';

const ticker = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
`;

const Wrapper = styled.div`
  background: #ffdd00;
  z-index: ${zIndex.header};
  position: sticky;
  top: 8.8rem;
  text-align: center;
  padding: 12px 0;
  overflow: hidden;

  ${font.banner};

  ${(props) => props.theme.mq.medium`
      top: 7.2rem;
      ${font.h4};
  `};

  ${(props) => props.theme.mq.medium_up`
    padding: 20px 0;
  `};

  ${(props) => props.theme.mq.small`

      .ticker-wrap {
        bottom: 0;
        width: 100%;
        overflow: hidden;
        padding-left: 100%;
        box-sizing: content-box;

        .ticker {
          display: inline-block;
          white-space: nowrap;
          padding-right: 100%;
          box-sizing: content-box;

          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-name: ${ticker};
          animation-duration: 10s;

          .item {
            display: inline-block;
          }
        }
      }
  `};
`;
Banner.propTypes = {
  text: PropTypes.string,
};
export default function Banner({ text }) {
  return (
    <Wrapper>
      <div className="ticker-wrap">
        <div className="ticker">
          <div className="item">{text}</div>
        </div>
      </div>
    </Wrapper>
  );
}
