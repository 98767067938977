import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Container as ContainerElement,
  Inner as InnerElement,
  GenericPageWrapper,
} from '../components/Elements';
import SectionTitleBody from '../components/SectionTitleBody';
import SEO from '../components/SEO';

import { colors } from '../consts/style';
import FAQList from '../components/FAQList';
import PhoneCTA from '../components/PhoneCTA';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Container = styled(ContainerElement)`
  overflow: hidden;
`;
const Inner = styled(InnerElement)`
  flex-direction: column;

  .faq-wrapper {
    margin: 10rem 0;
  }

  .cta-wrapper {
    margin-bottom: 12rem;
  }
`;

FaqPage.propTypes = { data: PropTypes.object.isRequired };

function FaqPage({ data: { prismicCmsFaq: pageData } }) {
  const seoData = {
    title: pageData.data.title_tag,
    description: pageData.data.meta_description,
    image: pageData.data.og_image?.url || null,
    slug: '/faqs',
  };

  return (
    <GenericPageWrapper>
      <SEO {...seoData} />
      <Container>
        <Inner>
          <SectionTitleBody
            title={{
              text: pageData.data.title,
              element: 'h2',
              maxWidth: '71.3rem',
              maxWidthMobile: '36rem',
            }}
            color={colors.generics.black}
          />
          <div className="faq-wrapper">
            <FAQList faqs={pageData.data.faqs} />
          </div>
          <div className="cta-wrapper">
            <SectionTitleBody
              title={{
                text: 'Don’t see what you’re looking for?',
                element: 'h2',
                maxWidth: '71.3rem',
                maxWidthMobile: '36rem',
              }}
              cta={{
                text: 'Visit our online help',
                to: 'https://help.braid.co',
              }}
              color={colors.generics.black}
            />
          </div>
        </Inner>
      </Container>
      {/* <PhoneCTA buttonText="Get the App" signUpBonusBoolean={pageData.data.sign_up_bonus_boolean}/> */}
    </GenericPageWrapper>
  );
}

export default withPrismicPreview(FaqPage);

export const faqQuery = graphql`
  {
    prismicCmsFaq {
      _previewable
      data {
        title_tag
        sign_up_bonus_boolean
        meta_description
        og_image {
          url
        }
        title
        faqs {
          question
          answer {
            html
            text
          }
        }
      }
    }
  }
`;
