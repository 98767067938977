import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG = styled.svg`
  transition: all 0.3s ease;
`;

LogoMarkCompact.propTypes = { fill: PropTypes.string };

export default function LogoMarkCompact({ fill = 'white' }) {
  return (
    <SVG
      width="101"
      height="28"
      viewBox="0 0 101 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.7069 5.12127L40.3547 3.72962C38.2453 1.55865 35.4868 0.445328 32.6742 0.445328H32.566C30.9433 0.445328 29.2666 0.83499 27.7521 1.61431L24.2904 3.39563L14.0677 0L0.653809 9.51889L9.5784 28H18.8275L23.5873 24.7714L26.8867 26.3857C28.4012 27.165 30.0238 27.499 31.6465 27.499C35 27.499 38.2994 25.8847 40.4088 22.9901L42.7346 19.7614C46.034 15.3638 45.6013 9.07356 41.7069 5.12127ZM18.0703 25.495H11.0929L3.84503 10.4095L14.5004 2.83897L21.3156 5.12127C18.3948 7.23658 16.664 10.6322 16.664 14.3618C16.664 17.9801 18.3948 21.3757 21.1533 23.4911L18.0703 25.495ZM23.425 21.8767C20.8288 20.3181 19.1521 17.4235 19.1521 14.3062C19.1521 10.9662 20.937 8.0159 23.8577 6.51292L24.5609 6.17893L26.1835 6.73559L29.7534 17.7018L23.425 21.8767ZM40.7874 18.3141L38.4616 21.5427C36.8931 23.7137 34.3509 25.0497 31.6465 25.0497C30.3484 25.0497 29.1043 24.7714 27.9685 24.159L26.0213 23.1571L32.6742 18.6481L28.0766 4.67594L27.6439 4.50895L28.8339 3.89662C30.0238 3.28429 31.3219 2.9503 32.6201 2.9503C34.8918 2.9503 37.0012 3.84095 38.6239 5.51093L39.9761 6.90258C42.951 9.96421 43.2755 14.8628 40.7874 18.3141Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M61.1911 14.0147C62.3566 13.6003 63.2631 12.3571 63.2631 10.6477C63.2631 7.7987 61.1911 6.3224 57.8759 6.3224H52.3074V22.225H58.3939C61.7091 22.225 63.7811 20.6451 63.7811 17.6666C63.7811 15.9831 62.6933 14.5068 61.1911 14.0147ZM54.9233 13.0046V8.6534H57.85C59.6889 8.6534 60.6731 9.3009 60.6731 10.8031C60.6731 12.3053 59.6889 13.0046 57.85 13.0046H54.9233ZM54.9233 15.232H58.3421C60.1551 15.232 61.1393 16.1126 61.1393 17.563C61.1393 18.9875 60.1551 19.894 58.3421 19.894H54.9233V15.232Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M72.3224 10.6736C72.1411 10.57 71.6231 10.4664 71.0533 10.4664C69.9396 10.4664 68.9295 11.0621 68.4892 11.9168V10.7254H65.8992V22.225H68.4892V14.7917C68.7482 13.5485 69.7324 12.9528 70.7943 12.9528C71.3382 12.9528 71.9598 13.0823 72.3224 13.3413V10.6736Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M82.6616 22.225V14.6881C82.6616 12.0722 80.9522 10.4664 78.0514 10.4664C75.6945 10.4664 73.8815 11.9427 73.5448 13.9111H76.0312C76.3161 13.1082 76.9636 12.6679 77.9996 12.6679C79.4759 12.6679 80.2011 13.5744 80.2011 14.7399V15.6723C79.7349 15.3356 78.5694 14.973 77.5334 14.973C74.9952 14.973 73.0527 16.5011 73.0527 18.6767C73.0527 21.0336 74.9952 22.4322 77.3521 22.4322C78.6471 22.4322 79.8126 21.966 80.2011 21.5775V22.225H82.6616ZM80.2011 19.1688C79.9162 19.894 78.8802 20.3343 77.8442 20.3343C76.6787 20.3343 75.4614 19.8422 75.4614 18.6767C75.4614 17.5371 76.6787 17.045 77.8442 17.045C78.8802 17.045 79.9162 17.4853 80.2011 18.2105V19.1688Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M87.7888 10.7254H85.1988V22.225H87.7888V10.7254ZM88.0996 7.2548C88.0996 6.3742 87.3485 5.6749 86.4938 5.6749C85.6391 5.6749 84.888 6.3742 84.888 7.2548C84.888 8.1354 85.6391 8.8347 86.4938 8.8347C87.3485 8.8347 88.0996 8.1354 88.0996 7.2548Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M97.9321 22.225H100.522V5.39H97.9321V11.7096C97.414 11.0621 96.2745 10.4664 95.0054 10.4664C91.6384 10.4664 89.67 13.1859 89.67 16.4752C89.67 19.7904 91.6384 22.484 95.0054 22.484C96.2745 22.484 97.414 21.8883 97.9321 21.2408V22.225ZM97.9321 18.6508C97.4917 19.5573 96.5075 20.1789 95.4456 20.1789C93.4514 20.1789 92.2599 18.599 92.2599 16.4752C92.2599 14.3514 93.4514 12.7715 95.4456 12.7715C96.5075 12.7715 97.4917 13.3931 97.9321 14.2996V18.6508Z"
        fill={fill}
        fillOpacity="0.95"
      />
    </SVG>
  );
}
