import { createGlobalStyle } from 'styled-components';
import { font, colors } from '../consts/style';

const Global = createGlobalStyle`
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  ${font.j1};
}
h2 {
  ${font.j2};
}
h3 {
  ${font.j3};
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

a:hover {
  text-decoration: none;
}
a {
  transition: background .3s ease, color .3s ease;
}
img {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

a {
  color: ${colors.purple.primary};
  &:hover {
    color: ${colors.purple.primary};
  }
}

strong,
b {
  font-weight: 600;
}

`;

export default Global;
