import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import fallbackImage from '../images/social.jpg';

export default function SEO({
  title,
  description,
  slug = '',
  image,
  overrideCanonical,
}) {
  const createUrl = (slug) => `https://braid.co${slug}`,
    url = createUrl(slug);
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || fallbackImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image || fallbackImage} />
      {overrideCanonical && (
        <link
          rel="canonical"
          href={url}
          data-baseprotocol="https:"
          data-basehost="braid.co"
        />
      )}
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.string,
  overrideCanonical: PropTypes.bool,
};
