import { css } from 'styled-components';
import bp from './breakpoints';

/**
 * Colors
 */
export const colors = {
  generics: {
    black: '#000',
    white: '#fff',
  },
  elements: {
    divider: '#e5e3df',
    background: '#FAF9F5',
    inputBorder: '#cccac6',
  },
  purple: {
    primary: '#4B52D9',
    primaryDark: '#393FA6',
    primaryLight: '#A1A4E5',
    primaryLightest: '#E1E4F2',
    hover: '#545CF2',
  },
  yellow: {
    primary: '#FFDD00',
  },
  teal: {
    primary: '#72D9E1',
  },
  accent: {
    green: '#01BF58',
    red: '#FF4259',
    yellow: '#FFC805',
    orange: '#FFA31C',
    blue: '#4987FF',
    teal: '#72D9E1',
  },
  text: {
    primary: '#000',
    secondary: '#636260',
    tertiary: '#999794',
    success: '#2D9164',
    error: '#D91C41',
    purpleLight: '#D2D4F5',
    highlight: '#2D9164',
  },
};

/**
 * Buttons
 */
const buttonBase = `
border-radius: 100rem;
display: inline-block;
flex-shrink: 0;
border: none;
transition: background .3s ease, color .3s ease;
`;

const buttonMedium = `
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem;
  padding: 1.2rem 2rem;
`;

const buttonLarge = `
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 2.8rem;
  padding: 1.8rem 3.2rem;
`;

const buttonPrimary = `
  color: ${colors.generics.white};
  background: ${colors.purple.primary};
  &:hover {
    background: ${colors.purple.primaryDark};
  }
  &:[disabled] {
    background: ${colors.purple.primaryDark};
    pointer-events: none;
  }
`;

const buttonSecondary = `
  color: ${colors.purple.primary};
  background: ${colors.purple.primaryLightest};
  &:hover {
    background: ${colors.purple.primaryLightest};
  }
  &:[disabled] {
    background: ${colors.purple.primaryLightest};
    pointer-events: none;
  }
`;

const buttonDark = `
  color: ${colors.generics.white};
  background: ${colors.generics.black};
  &:hover {
    background: ${colors.generics.black};
  }
  &:[disabled] {
    opacity: .75;
    pointer-events: none;
  }
`;

/**
 * Font utilities
 */
export const font = {
  founders: `
    font-family: "Founders Grotesque", Impact;
  `,
  calibre: `
    font-family: "Calibre", Helvetica, Arian, sans-serif;
  `,
  body: `
        font-size: 1.6rem;
    `,
  bodyLarge: `
      font-style: normal;
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 2.8rem;
      @media screen and (max-width: ${bp.medium}px) {
        font-size: 2rem;
        line-height: 2.2rem;
      }
    `,
  faqTitle: `
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
  `,
  faqBody: `
    font-size: 2.1rem;
    line-height: 2.4rem;
    font-weight: 400;
  `,
  input: `
      color: ${colors.text.primary};
      font-size: 2.5rem;
      line-height: 2.8rem;
  `,
  placeholder: `
      color: ${colors.text.tertiary};
      font-size: 2.5rem;
      line-height: 2.8rem;
  `,
  j1: `
    font-family: "Founders Grotesque", Impact;
    font-size: 11rem;
    font-weight: 700;
    line-height: 8.8rem;
    text-transform: uppercase;
    @media screen and (max-width: ${bp.small}px) {
      font-size: 6.9rem;
      line-height: 5.6rem;
    }
  `,
  j2: `
    font-family: "Founders Grotesque", Impact;
    font-size: 9rem;
    font-weight: 700;
    line-height: 7.6rem;
    @media screen and (max-width: ${bp.small}px) {
      font-size: 6rem;
      line-height: 5.6rem;
    }
  `,
  j3: `
    font-family: "Founders Grotesque", Impact;
    font-size: 7.2rem;
    font-weight: 700;
    line-height: 6.4rem;
    @media screen and (max-width: ${bp.small}px) {
      font-size: 4.8rem;
      line-height: 4.4rem;
    }
  `,
  banner: `
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 500;
    font-family: "Calibre", Helvetica, Arian, sans-serif;

    @media screen and (max-width: ${bp.small}px) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  `,
  p1: `
    font-size: 2.6rem;
    line-height: 3.2rem;
    font-weight: 400;
  `,
  p2: `
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 400;
  `,
  p3: `
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
  `,
  cardTitle: `
    font-family: "Calibre", Helvetica, Arian, sans-serif;
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 600;
  `,
  highlight: `
    font-family: "Calibre", Helvetica, Arian, sans-serif;
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 600;
    text-transform: none;
  `,
  genericH3: `
    font-size: 3.2rem;
    line-height: 3.2rem;
    font-weight: 600;

    @media screen and (max-width: ${bp.small}px) {
      font-size: 2.4rem;
      line-height: 2.4rem;
    }
  `,
  h1: `
      font-family: "Founders Grotesque", Impact;
      font-style: normal;
      font-weight: bold;
      font-size: 11rem;
      line-height: 9rem;
      text-transform: uppercase;
      @media screen and (max-width: ${bp.medium}px) {
        font-size: 9rem;
        line-height: 6.8rem;
      }
    `,
  h2: `
      font-family: "Founders Grotesque", Impact;
      font-style: normal;
      font-weight: bold;
      font-size: 9rem;
      line-height: 8rem;
      text-transform: uppercase;
      @media screen and (max-width: ${bp.medium}px) {
        font-size: 8.2rem;
        line-height: 6.6rem;
      }
    `,
  h3: `
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
  `,
  h4: `
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
  `,
  mobileNavLinks: `
      font-size: 2.6rem;
      line-height: 3.2rem;
      font-weight: 500;
    `,
  buttonMediumPrimary: `
  ${buttonBase}
  ${buttonMedium}
  ${buttonPrimary}
  `,
  buttonLargePrimary: `
  ${buttonBase}
  ${buttonLarge}
  ${buttonPrimary}
  `,
  buttonMediumSecondary: `
  ${buttonBase}
  ${buttonMedium}
  ${buttonSecondary}
  `,
  buttonLargeSecondary: `
  ${buttonBase}
  ${buttonLarge}
  ${buttonSecondary}
  `,
  buttonMediumDark: `
  ${buttonBase}
  ${buttonMedium}
  ${buttonDark}
  `,
  buttonLargeDark: `
  ${buttonBase}
  ${buttonLarge}
  ${buttonDark}
  `,
};

/**
 * Z-index
 */
export const zIndex = {
  body: 10,
  header: 1000,
  mobileMenu: 2000,
};

/**
 * Media queries
 */
export const mq = Object.keys(bp).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  acc[`${label}_up`] = (...args) => css`
    @media screen and (min-width: ${bp[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
