import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/SEO';
import Banner from '../components/Banner';
import FAQList from '../components/FAQList';
import PhoneCTA from '../components/PhoneCTA';
import { Container, Inner } from '../components/Elements';

const Wrapper = styled.div`
  padding-top: 8.8rem;

  ${(props) => props.theme.mq.medium`
     padding-top: 7.2rem;
  `};

  ${Inner} {
    flex-direction: column;
  }

  .section-hero {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 6.4rem;
    margin-bottom: 12.8rem;

    .section-info {
      flex: 1;
      padding-right: 4rem;
      flex-shrink: 0;
      h1 {
        ${(props) => props.theme.font.j1};
        text-transform: none;
        line-height: 9.6rem;
        margin-bottom: 2rem;
      }
      p {
        ${(props) => props.theme.font.p1};
        margin-bottom: 4rem;
      }

      button {
        ${(props) => props.theme.font.buttonLargePrimary};
        margin-bottom: 0.8rem;
      }
      span {
        color: ${(props) => props.theme.colors.text.secondary};
      }

      ${(props) => props.theme.mq.small`
            button, .sub {
              # Hide again after we return to the app store
              # display: none;
            }
        `};
    }
    .section-image {
      flex: 1;
      padding-left: 4rem;

      .section-image-inner {
        text-align: center;
        width: 100%;
        max-width: 45.2rem;
        margin: auto;
      }
    }

    ${(props) => props.theme.mq.large`
      flex-direction: column;
      margin-top: 4rem;

      .section-info {
        order: 1;
        width: 100%;
        padding: 0;
        text-align: center;

        h1 {
          max-width: 60rem;
          margin: auto;
          margin-bottom: 2rem;
        }
      }
      .section-image {
        padding: 0;
        order: 0;
        margin-bottom: 2rem;

        .section-image-inner {
          width: 27.5rem;
        }
      }
    `}

    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `}
  }

  .section-title {
    width: 100%;
    text-align: center;
    margin-bottom: 6.4rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 4.8rem;
    `}
  }

  .section-one {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    .list-items {
      margin-top: 6.4rem;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }

      .section-row {
        max-width: 30%;
        width: 33rem;

        .section-image-content {
          margin-bottom: 2rem;
          text-align: center;

          .section-image-content-inner {
            text-align: center;
            max-width: 32rem;
            margin: auto;
          }

          ${(props) => props.theme.mq.small`
            width: 100%;
          `};
        }

        .section-row-content {
          text-align: center;
          padding-top: 2rem;
          h1 {
            font-weight: 600;
            font-family: Calibre, Helvetica, Arian, sans-serif;
            text-transform: none;
            margin-bottom: 1.2rem;
            ${(props) => props.theme.font.genericH3}
          }
          p {
            ${(props) => props.theme.font.p2}
          }
        }
      }

      ${(props) => props.theme.mq.large`
        flex-wrap: wrap;
        .section-row {
          width: 100%;
          max-width: 100%;
          margin-bottom: 6.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;


          .section-image-content, .section-row-content {
            width: calc(50% - 4rem);
            text-align: left;
          }

          &:nth-child(odd) {
            .section-image-content {
              order: 1;
            }
            .section-row-content {
              order: 0;
              padding: 0;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      `}

      ${(props) => props.theme.mq.medium`
        .section-row {
          .section-image-content, .section-row-content {
            width: calc(50% - 2rem);
            text-align: left;
          }
        }
      `}

      ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;

        .section-row {
          flex-wrap: wrap;

          .section-image-content, .section-row-content {
            width: 100%;
          }

            .section-image-content {
              order: 0 !important;
            }
            .section-row-content {
              order: 1 !important;
              text-align: center;
            }


          &:last-child {
            margin: 0;
          }
        }
      `}
    }
  }

  .section-two {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    .section-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6.4rem;

      ${(props) => props.theme.mq.large`
      justify-content: space-between;
      `};

      ${(props) => props.theme.mq.small`
        flex-wrap: wrap;
      `};

      &:last-child {
        margin: 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        color: ${(props) => props.theme.colors.text.highlight};
        ${(props) => props.theme.font.highlight};
      }

      p {
        margin-bottom: 4rem;
        ${(props) => props.theme.font.p2};
      }

      .section-row-content,
      .section-image-content {
        width: 100%;
        max-width: 42rem;

        ${(props) => props.theme.mq.large`
        width: calc(50% - 4rem);
        `};
        ${(props) => props.theme.mq.medium`
        width: calc(50% - 2rem);
        `};
        ${(props) => props.theme.mq.small`
        width: 100%;
        max-width: none;
        `};
      }

      .section-image-wrapper {
        margin: 0 auto;
      }

      .section-image-content {
        text-align: center;
        ${(props) => props.theme.mq.small`
            margin: 0;
            margin-bottom: 4rem;
            width: 100%;
          `};
      }

      &:nth-child(odd) {
        .section-row-content {
          order: 0;
          margin-right: 12.8rem;
          ${(props) => props.theme.mq.large`
            margin-right: 8rem;
          `};
          ${(props) => props.theme.mq.medium`
            margin-right: 4rem;
          `};
          ${(props) => props.theme.mq.small`
            order: 1;
            margin-right: 0;
          `};
        }
        .section-image-content {
          order: 1;
          ${(props) => props.theme.mq.small`
            order: 0;
          `};
        }
      }
      &:nth-child(even) {
        .section-row-content {
          margin-left: 12.8rem;
          ${(props) => props.theme.mq.large`
            margin-left: 8rem;
          `};
          ${(props) => props.theme.mq.medium`
            margin-left: 4rem;
          `};
          ${(props) => props.theme.mq.small`
            margin-left: 0;
          `};
        }
      }
    }
  }

  .section-three {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};
    .list-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -1rem;

      .list-item {
        padding: 24px 32px;
        background: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        color: #000;
        margin: 0 1rem 2rem;
        :hover {
          background: #e1e4f2;
          color: #4b52d9;
        }
        span {
          font-family: Calibre;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 28px;
        }
        ${(props) => props.theme.mq.medium`
        border-radius: 12px;
        padding: 16px 24px;
        margin: 0px .75rem 1.5rem;
        span {
          font-size: 24px;
          line-height: 24px;
        }
        `};
        ${(props) => props.theme.mq.small`
          border-radius: 8px;
          padding: 12px 20px;
          span {
            font-size: 19px;
            line-height: 20px
          }
        `};
      }
    }
  }
  .section-four {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};
    .list-items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 8rem;
      text-align: center;
      p {
        ${(props) => props.theme.font.p2}
      }

      ${(props) => props.theme.mq.large`
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 4rem;

        p {
          max-width: 48rem;
          margin: 0 auto;
        }
      `};
    }
  }

  .section-faqs {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function IndexPage({ data: { prismicCmsHomepage: pageData } }) {
  const seoData = {
    title: pageData.data.title_tag,
    description: pageData.data.meta_description,
    image: pageData.data.og_image?.url || null,
    slug: '/',
  };

  const renderSectionOneItems = () => {
    return pageData.data.section_1_items.map((s) => {
      return (
        <div className="section-row" key={s.title1}>
          <div className="section-image-content">
            <div className="section-image-content-inner">
              <GatsbyImage
                alt={s.image.alt || s.title1}
                image={s.image.gatsbyImageData}
              />
            </div>
          </div>
          <div className="section-row-content">
            <h1>{s.title}</h1>
            <p>{s.body}</p>
          </div>
        </div>
      );
    });
  };

  const renderSectionTwoItems = () => {
    return pageData.data.section_2_items.map((s) => {
      const getMaxWidth = () => {
        if (s.image_max_width !== undefined) {
          return {
            maxWidth: `${s.image_max_width}px`,
            width: '100%',
            margin: '0 auto',
          };
        }
        return { margin: '0 auto' };
      };
      return (
        <div className="section-row" key={s.image.url}>
          <div className="section-image-content">
            <div className="section-image-wrapper">
              <div style={getMaxWidth()}>
                <GatsbyImage
                  alt={s.image.alt || s.title1}
                  image={s.image.gatsbyImageData}
                />
              </div>
            </div>
          </div>
          <div className="section-row-content">
            <div dangerouslySetInnerHTML={{ __html: s.content.html }}></div>
          </div>
        </div>
      );
    });
  };

  const renderSectionThreeItems = () => {
    return pageData.data.section_3_items.map((s) => {
      return (
        <a className="list-item" key={s.label} href={s.link.url}>
          <div>
            <span>{s.label}</span>
          </div>
        </a>
      );
    });
  };

  const renderSectionFourItems = () => {
    return pageData.data.section_4_items.map((s) => {
      return (
        <div className="section-row" key={s.image.url}>
          <div className="section-image-content">
            <img alt={s.image.alt} src={s.image.url} />
          </div>
          <div className="section-row-content">
            <p>{s.body}</p>
          </div>
        </div>
      );
    });
  };

  const onClickCtaOpenExternalLink = (url) => {
    window && window.open(url, '_blank');
  };

  return (
    <Wrapper>
      <SEO {...seoData} />
      {pageData.data.banner_text && <Banner text={pageData.data.banner_text} />}
      <Container>
        <Inner>
          <section className="section-hero">
            <div className="section-info">
              <h1>{pageData.data.hero_title}</h1>
              <p>{pageData.data.hero_body}</p>
              {pageData.data.hero_button_text &&
                pageData.data.hero_link_button.url && (
                  <button
                    onClick={() =>
                      onClickCtaOpenExternalLink(
                        pageData.data.hero_link_button.url
                      )
                    }
                  >
                    {pageData.data.hero_button_text}
                  </button>
                )}
            </div>
            <div className="section-image">
              <div className="section-image-inner">
                <GatsbyImage
                  alt={pageData.data.hero_body.alt || 'hero'}
                  image={pageData.data.hero_image.gatsbyImageData}
                />
              </div>
            </div>
          </section>
          <section className="section-one">
            <div className="section-title">
              <h2>{pageData.data.section_1_title}</h2>
            </div>
            <div className="list-items">{renderSectionOneItems()}</div>
          </section>
          <section className="section-two">{renderSectionTwoItems()}</section>
          <section className="section-three">
            <div className="section-title">
              <h2>{pageData.data.section_3_title}</h2>
            </div>
            <div className="list-items">{renderSectionThreeItems()}</div>
          </section>
          <section className="section-four">
            <div className="section-title">
              <h2>{pageData.data.section_4_title}</h2>
            </div>
            <div className="list-items">{renderSectionFourItems()}</div>
          </section>
          <section className="section-faqs">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
            <FAQList faqs={pageData.data.faqs} />
          </section>
        </Inner>
      </Container>
      <PhoneCTA
        buttonText="Get the App"
        signUpBonusBoolean={pageData.data.sign_up_bonus_boolean}
      />
    </Wrapper>
  );
}

export default withPrismicPreview(IndexPage);

export const indexQuery = graphql`
  {
    prismicCmsHomepage {
      _previewable
      data {
        # SEO FIELDS
        title_tag
        meta_description
        og_image {
          url
        }
        banner_text
        hero_title
        hero_body
        hero_image {
          url
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        hero_button_text
        hero_link_button {
          url
        }
        sign_up_bonus_boolean
        hero_sub_text
        section_1_title
        section_1_items {
          title
          body
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }

        section_2_items {
          content {
            html
          }
          image_max_width
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        section_3_title
        section_3_items {
          label
          link {
            url
          }
        }
        section_4_title
        section_4_items {
          body
          image {
            url
            alt
          }
        }
        faqs {
          question
          answer
        }
      }
    }
  }
`;
