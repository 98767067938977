import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { Container, Inner } from '../components/Elements';
import PostListCompact from '../components/PostListCompact';
import SEO from '../components/SEO';

import { colors } from '../consts/style';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PhoneCTA from '../components/PhoneCTA';

const Wrapper = styled.div`
  margin-bottom: 0;
  padding-top: 20rem;

  ${(props) => props.theme.mq.small`
    padding-top: 16.8rem;
  `}

  .fullwidth-image-block {
    margin-bottom: 3.2rem;
    margin-top: 1.6rem;
    width: 100%;
    .inner {
      width: 100%;
      margin: 0 auto;
    }
  }
  .callout-block {
    width: 100%;
    max-width: 72rem;
    margin: 3.2rem auto;
    border: 2px solid #545cf2;
    border-radius: 1.2rem;
    padding: 3.2rem 4rem;

    h2 {
      font-size: 2.8rem;
      line-height: 2.8rem;
      font-family: Calibre;
      font-weight: 600;
      margin-bottom: 0.4rem;
    }

    a {
      font-size: 2.6rem;
      line-height: 3.2rem;
      display: flex;
      align-items: center;

      span {
        margin-right: 0.4rem;
      }
    }

    ${(props) => props.theme.mq.small`
    padding: 2.4rem;
      h2 {
        font-size: 2.4rem;
        line-height: 2.4rem;
      }
      a {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    `}
  }
  .post-item-image {
    width: 100%;
    max-width: 72rem;
    margin: 3.2rem auto;
  }
  .post-item-text {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;

    h1 {
      font-family: Calibre, Helvetica, Arial, sans-serif;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.2rem;
      margin: 1.6rem 0;
    }

    h2 {
      font-family: Calibre, Helvetica, Arial, sans-serif;
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.8rem;
      margin: 1.6rem 0;
    }

    h3 {
      font-family: Calibre, Helvetica, Arial, sans-serif;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.8rem;
      margin: 1.6rem 0;
    }

    p,
    ul,
    ol {
      margin-bottom: 1.6rem;
      ${(props) => props.theme.font.p1}

      ${(props) => props.theme.mq.small`
      font-weight: 400;
      font-size: 2.3rem;
      line-height: 2.8rem;
      `}
    }

    a {
      color: ${colors.purple.primary};
      text-decoration: underline;
      text-decoration-color: ${colors.purple.primary};
      transition: text-decoration-color 0.2s ease;
      &:hover {
        text-decoration-color: transparent;
      }
    }
  }

  .post-title {
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 6.4rem;
    display: flex;
    flex-direction: column;

    ${(props) => props.theme.mq.small`
      margin-bottom: 4rem;
    `}

    h1 {
      ${(props) => props.theme.font.j2};
      text-transform: none;
      line-height: 1;
      margin-bottom: 3.2rem;

      ${(props) => props.theme.mq.small`
      margin-bottom: 1.6rem;
      `}
    }

    p {
      ${(props) => props.theme.font.p2};
      color: ${(props) => props.theme.colors.text.secondary};
      margin: 0 !important;
    }

    small {
      ${(props) => props.theme.font.p1};
      color: ${(props) => props.theme.colors.purple.primary};
      margin-bottom: 1.2rem;

      ${(props) => props.theme.mq.small`
      margin-bottom: 0.8rem;
      `}
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    margin: 0;
    margin-top: 10.8rem;

    ${(props) => props.theme.mq.small`
      margin-top: 7.6rem;
  `}
  }
`;

const RecentPosts = styled(Container)`
  margin-top: 12.8rem;

  ${(props) => props.theme.mq.small`
      margin-top: 9.6rem;
  `}

  ${Inner} {
    flex-direction: column;
  }
  .title,
  .posts {
    width: 100%;
  }

  .title {
    text-align: center;
    ${(props) => props.theme.font.j2};
  }
`;

BlogPost.propTypes = { data: PropTypes.object, pageContext: PropTypes.object };

function BlogPost({
  data: {
    prismicCmsBlogPost: { data },
  },
  pageContext,
}) {
  const recentPostProps = {
    posts: pageContext.recentPosts.slice(0, 4),
    overridePrev: pageContext.prevPostId
      ? `/post/${pageContext.prevPostId}`
      : null,
    overrideNext: pageContext.nextPostId
      ? `/post/${pageContext.nextPostId}`
      : null,
    showFeaturedPost: false,
    showControls: false,
  };

  const headerRegex = /<(h[1-6])>([^<]+)<\/h[1-6]>/gi;

  return (
    <Wrapper>
      <SEO
        title={data.title_tag}
        description={data.meta_description}
        image={data.og_image?.url}
      />
      <Container>
        <Inner>
          <div className="post-title">
            <Link to="/blog">
              <small>Braid Blog</small>
            </Link>
            <h1>{data.title}</h1>
            <div className="post-item-text byline">
              <p>Posted on {data.publish_date}</p>
            </div>
          </div>
        </Inner>
      </Container>

      <article className="post-content">
        {data.body &&
          data.body.map((b) => {
            if (b.__typename === 'PrismicCmsBlogPostDataBodyTextBlock')
              return (
                <Container key={b.id}>
                  <Inner>
                    <div className="post-item-text">
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: b.primary.text.html.replaceAll(
                            headerRegex,
                            (match, tagType, content) => {
                              const idValue = content
                                .replaceAll(' ', '-')
                                .replaceAll(/[^a-zA-Z-]+/g, '')
                                .trimRight('-');
                              return (
                                '<' +
                                tagType +
                                ' id=' +
                                idValue +
                                '>' +
                                content +
                                '</' +
                                tagType +
                                '>'
                              );
                            }
                          ),
                        }}
                      />
                    </div>
                  </Inner>
                </Container>
              );
            if (b.__typename === 'PrismicCmsBlogPostDataBodyInlineImageBlock')
              return (
                <Container key={b.id}>
                  <Inner>
                    <div className="post-item-image">
                      <Img
                        fluid={b.primary.image.fluid}
                        backgroundColor={colors.elements.background}
                      />
                    </div>
                  </Inner>
                </Container>
              );
            if (b.__typename === 'PrismicCmsBlogPostDataBodyImageBlock')
              return (
                <Container key={b.id}>
                  <Inner>
                    <div className="fullwidth-image-block">
                      <div className="inner">
                        <Img
                          fluid={b.primary.image.fluid}
                          backgroundColor={colors.elements.background}
                        />
                      </div>
                    </div>
                  </Inner>
                </Container>
              );
            return null;
          })}
        <Container>
          <Inner>
            <hr />
          </Inner>
        </Container>
      </article>
      <RecentPosts>
        <Inner>
          <div className="posts">
            <PostListCompact {...recentPostProps} />
          </div>
        </Inner>
      </RecentPosts>
      <PhoneCTA buttonText="Get the App" />
    </Wrapper>
  );
}

export const blogPostQuery = graphql`
  query ($uid: String!) {
    prismicCmsBlogPost(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title
        title_tag
        meta_description
        og_image {
          url
        }
        publish_date(formatString: "MMMM D, YYYY")
        author
        body {
          ... on PrismicCmsBlogPostDataBodyTextBlock {
            __typename
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicCmsBlogPostDataBodyImageBlock {
            __typename
            id
            primary {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicCmsBlogPostDataBodyInlineImageBlock {
            __typename
            id
            primary {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicCmsBlogPostDataBodyCalloutBlock {
            __typename
            id
            primary {
              title1
              link
              link_label
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(BlogPost);
