import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from '../components/SEO';
import { Container, Inner } from '../components/Elements';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PhoneCTA from '../components/PhoneCTA';

const Wrapper = styled.div`
  padding-top: 8.8rem;
  display: flex;
  flex-direction: column;

  p {
    ${(props) => props.theme.font.p2};
  }

  .section-title {
    width: 100%;
    text-align: center;
    margin-top: 12.8rem;
    margin-bottom: 6.4rem;

    ${(props) => props.theme.mq.small`
      margin-top: 9.6rem;
      margin-bottom: 4.8rem;
    `};
  }

  .section-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8rem;
    margin-bottom: 12.8rem;

    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    ${(props) => props.theme.mq.medium`
      gap: 4rem;
    `};

    ${(props) => props.theme.mq.small`
      flex-wrap: wrap;
    `};

    .section-one-image,
    .section-one-content {
      width: 50%;
      max-width: 53.6rem;
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        margin-bottom: 2rem;
      }

      p {
        ${(props) => props.theme.font.p1};
      }

      ${(props) => props.theme.mq.small`
        width: 100%;
      `};
    }
    .section-one-image {
      text-align: center;
      ${(props) => props.theme.mq.small`
        order: 0;
      `};
    }
    .section-one-content {
      ${(props) => props.theme.mq.small`
        order: 1;
      `};
    }
  }

  .section-two-container {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.medium`
      padding: 0;
    `};
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    ${Inner} {
      background: ${(props) => props.theme.colors.teal.primary};
      max-width: 127rem;
      padding: 9.6rem 6.4rem;

      ${(props) => props.theme.mq.medium`
        padding: 9.6rem 0;
      `};
    }

    .section-two {
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;

      .section-two-image {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;

        ${(props) => props.theme.mq.medium`
          min-height: 28.2rem;
          overflow: hidden;
          position: relative;

          .section-two-image-inner {
            position: absolute;
            width: 96.9rem;
            height: 28.2rem;
          }
        `};
      }
      .section-two-content {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;
        width: 100%;
        max-width: 53.6rem;
        margin: 0 auto;

        ${(props) => props.theme.mq.medium`
          padding: 0 4rem;
          max-width: 100%;
        `};
        ${(props) => props.theme.mq.small`
          padding: 0 2rem;
          max-width: 100%;
        `};

        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-bottom: 2rem;
        }

        p {
          ${(props) => props.theme.font.p1};
        }
      }
    }
  }
  .section-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 8rem;
    margin-bottom: 12.8rem;

    ${(props) => props.theme.mq.medium`
      gap: 4rem;
    `};

    ${(props) => props.theme.mq.small`
      flex-wrap: wrap;
      margin-bottom: 9.6rem;
    `};

    .section-three-image,
    .section-three-content {
      width: 50%;
      max-width: 53.6rem;
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        margin-bottom: 2rem;
      }

      p {
        ${(props) => props.theme.font.p1};
      }

      ${(props) => props.theme.mq.small`
        width: 100%;
      `};

      a {
        ${(props) => props.theme.font.buttonMediumPrimary}
      }
    }
    .section-three-image {
      text-align: center;
    }
  }
`;

About.propTypes = {
  data: PropTypes.object.isRequired,
};

function About({ data: { prismicCmsAbout: pageData } }) {
  const seoData = {
    title: pageData.data.title_tag,
    description: pageData.data.meta_description,
    image: pageData.data.og_image?.url || null,
    slug: '/',
  };

  const renderTitle = () => {
    return (
      <Container>
        <Inner>
          <div className="section-title">
            <h1>{pageData.data.title}</h1>
          </div>
        </Inner>
      </Container>
    );
  };
  const renderSectionOne = () => {
    return (
      <Container>
        <Inner>
          <div className="section-one">
            <div className="section-one-content">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.data.section_1_content.html,
                }}
              />
            </div>
            <div className="section-one-image">
              <div className="section-one-image-inner">
                <img
                  src={pageData.data.section_1_image.url}
                  alt={pageData.data.section_1_image.alt}
                />
              </div>
            </div>
          </div>
        </Inner>
      </Container>
    );
  };
  const renderSectionTwo = () => {
    return (
      <Container className="section-two-container">
        <Inner>
          <div className="section-two">
            <div className="section-two-image">
              <div className="section-two-image-inner">
                <img
                  src={pageData.data.section_2_image.url}
                  alt={pageData.data.section_2_image.alt}
                />
              </div>
            </div>
            <div className="section-two-content">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.data.section_2_content.html,
                }}
              />
            </div>
          </div>
        </Inner>
      </Container>
    );
  };
  const renderSectionThree = () => {
    return (
      <Container>
        <Inner>
          <div className="section-three">
            <div className="section-three-image">
              <div className="section-three-image-inner">
                <img
                  src={pageData.data.section_3_image.url}
                  alt={pageData.data.section_3_image.alt}
                />
              </div>
            </div>
            <div className="section-three-content">
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.data.section_3_content.html,
                }}
              />
              <a href={pageData.data.section_3_cta_link} target="blank">
                {pageData.data.section_3_cta_text}
              </a>
            </div>
          </div>
        </Inner>
      </Container>
    );
  };

  return (
    <Wrapper>
      <SEO {...seoData} />
      {renderTitle()}
      {renderSectionOne()}
      {renderSectionTwo()}
      {renderSectionThree()}
      {/* <PhoneCTA buttonText="Get the App" signUpBonusBoolean={pageData.data.sign_up_bonus_boolean}/> */}
    </Wrapper>
  );
}

export default withPrismicPreview(About);

export const aboutQuery = graphql`
  {
    prismicCmsAbout {
      _previewable
      data {
        # SEO FIELDS
        title_tag
        meta_description
        og_image {
          url
        }
        # PAGE DATA
        title
        sign_up_bonus_boolean
        section_1_content {
          html
        }
        section_1_image {
          url
          alt
        }
        section_2_content {
          html
        }
        section_2_image {
          url
          dimensions {
            height
            width
          }
        }
        section_3_content {
          html
        }
        section_3_image {
          url
        }
        section_3_cta_text
        section_3_cta_link
      }
    }
  }
`;
