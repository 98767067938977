/* eslint-disable react/prop-types */
import * as React from 'react';
import { Link } from 'gatsby';
import { PrismicProvider } from '@prismicio/react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import linkResolver from '../../linkResolver';

import Homepage from '../pages/index';
import WhyPool from '../pages/why-pool';
import FAQs from '../pages/faqs';
import CoLiving from '../pages/coliving';
import About from '../pages/about';

import LegalTemplate from '../templates/Legal';
import BlogPostTemplate from '../templates/BlogPost';
import LandingTemplate from '../templates/LandingPage';
import LandingATemplate from '../templates/LandingPageTypeA';
import LandingBTemplate from '../templates/LandingPageTypeB';

export const PreviewProvider = ({ children }) => {
  return (
    <PrismicProvider
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: 'braid',
            linkResolver,
            componentResolver: componentResolverFromMap({
              homepage: Homepage,
              why_pool: WhyPool,
              faq: FAQs,
              pool_money: CoLiving,
              about: About,
              legal: LegalTemplate,
              blog_post: BlogPostTemplate,
              landing_page: LandingTemplate,
              landing_page_type_a: LandingATemplate,
              landing_page_type_b: LandingBTemplate,
            }),
          },
        ]}
      >
        {children}
      </PrismicPreviewProvider>
    </PrismicProvider>
  );
};
