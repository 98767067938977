/**
 * Generates json for Faq rich snippet
 * @param {*} faqs [{question, answer: {text}}]
 */
export const createFaqStructuredData = (faqs) => {
  const createFaqDataNodes = () => {
    return faqs.map((f) => {
      return `{"@type":"Question","name":"${f.question}","acceptedAnswer":{"@type":"Answer","text":"${f.answer?.text}"}}`;
    });
  };
  return `{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[${createFaqDataNodes()}]}`;
};
