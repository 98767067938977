import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import BackgroundImage from './BackgroundImage';

const PostLink = styled(Link)`
  display: flex;
  width: 100%;
  color: black;
  &:hover {
    color: black;

    h2 {
      color: ${(props) => props.theme.colors.purple.hover};
    }
  }

  h2 {
    transition: all 0.3s ease;
    ${(props) => props.theme.font.genericH3};
    font-family: Calibre;
    margin-bottom: 0.4rem;
  }
  p {
    ${(props) => props.theme.font.p1};
    margin-bottom: 0.4rem;

    ${(props) => props.theme.mq.small`
      display: none;
    `}
  }
  small {
    ${(props) => props.theme.font.p1};
    color: ${(props) => props.theme.colors.text.tertiary};
    margin: 0;
    ${(props) => props.theme.mq.medium`
    ${(props) => props.theme.font.p2};
    `}
  }

  .post-image {
    width: 100%;
    border-radius: 0.8rem;
    position: relative;
    overflow: hidden;

    .post-image-inner {
      width: 100%;
      padding-bottom: 56.25%;
    }
  }

  .post-details {
    order: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    padding-right: 2rem;
    flex-shrink: 1;
    width: 100%;

    p {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 2rem;

      ${(props) => props.theme.mq.small`
        display: none;
      `}
    }
  }
  .post-image {
    order: 1;
    width: 20rem;
    height: 15rem;
    flex-shrink: 0;
    border-radius: 0.8rem;

    img {
      border-radius: 0.8rem;
    }

    .post-image-inner {
      display: none;
      padding: 0;
    }
  }

  ${(props) => props.theme.mq.small`
     .post-details {
       width: 100%;
     }
     .post-image {
       order: 1;
       width: 9.6rem;
       height: 9.6rem;
       margin-bottom: 0;
     }
    `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 72rem;
  margin: auto;
  margin-bottom: 12.8rem;

  ${(props) => props.theme.mq.small`
    margin-bottom: 9.6rem;
    `}

  .title {
    width: 100%;
    text-align: left;
    margin-bottom: 6.4rem;

    ${(props) => props.theme.mq.small`
      margin-bottom: 4rem;
    `}
    h3 {
      ${(props) => props.theme.font.j2};
      text-transform: none;
      text-align: left;
    }
  }
  .posts-list {
    display: flex;
    flex-wrap: wrap;
    gap: 6.4rem;
    margin-bottom: 6.4rem;

    ${(props) => props.theme.mq.small`
    gap: 4rem;
    margin-bottom: 4rem;
    `}
  }
  .controls a {
    ${(props) => props.theme.font.buttonLargeSecondary}
  }
`;

PostListCompact.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default function PostListCompact({ posts }) {
  if (!posts.length) return null;

  return (
    <Wrapper>
      <div className="title">
        <h3>Recent Posts</h3>
      </div>
      <div className="posts-list">
        {posts.map((p) => (
          <PostLink
            title={p.data.title}
            className="post"
            to={`/post/${p.uid}`}
            key={p.uid}
          >
            <div className="post-image">
              <div className="post-image-inner"></div>
              <BackgroundImage
                image={p.data.og_image.gatsbyImageData}
                alt={p.data.title}
              />
            </div>
            <div className="post-details">
              <div className="post-details-inner">
                <h2>{p.data.title}</h2>
                <div className="post-snippet">
                  <p>{p.data.meta_description}</p>
                </div>
                <small>{p.data.publish_date}</small>
              </div>
            </div>
          </PostLink>
        ))}
      </div>
      <div className="controls">
        <Link to="/blog">See more posts</Link>
      </div>
    </Wrapper>
  );
}
