import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors } from '../../consts/style';

const SVG = styled.svg`
  transition: all 0.3s ease;
`;

Cancel.propTypes = { fill: PropTypes.string };

export default function Cancel({ fill = colors.generics.black }) {
  return (
    <SVG width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 9.29289C9.68342 8.90237 10.3166 8.90237 10.7071 9.29289L20 18.5858L29.2929 9.29289C29.6834 8.90237 30.3166 8.90237 30.7071 9.29289C31.0976 9.68342 31.0976 10.3166 30.7071 10.7071L21.4142 20L30.7071 29.2929C31.0976 29.6834 31.0976 30.3166 30.7071 30.7071C30.3166 31.0976 29.6834 31.0976 29.2929 30.7071L20 21.4142L10.7071 30.7071C10.3166 31.0976 9.68342 31.0976 9.29289 30.7071C8.90237 30.3166 8.90237 29.6834 9.29289 29.2929L18.5858 20L9.29289 10.7071C8.90237 10.3166 8.90237 9.68342 9.29289 9.29289Z" fill={fill} />
    </SVG>

  );
}
