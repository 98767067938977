import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG = styled.svg`
  transition: all 0.3s ease;
  width: 100%;
`;

Hamburger.propTypes = { fill: PropTypes.string };

export default function Hamburger({ fill = '#F8F7EF' }) {
  return (
    <SVG width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 16H33M7 24H33" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </SVG>
  );
}
