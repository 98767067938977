import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  GenericPageWrapper,
  Inner,
  LegalContentContainer,
} from '../components/Elements';
import SectionTitleBody from '../components/SectionTitleBody';
import Markdown from '../components/Markdown';
import SEO from '../components/SEO';

import { colors } from '../consts/style';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PhoneCTA from '../components/PhoneCTA';

Legal.propTypes = { data: PropTypes.object };

function Legal({ data: { prismicCmsLegal } }) {
  return (
    <GenericPageWrapper>
      <SEO
        title={prismicCmsLegal.data.title}
        description={`Last updated on ${prismicCmsLegal.data.last_updated}`}
      />
      <LegalContentContainer>
        <Inner>
          <SectionTitleBody
            title={{
              text: prismicCmsLegal.data.title,
              element: 'h2',
              maxWidth: '72rem',
              maxWidthMobile: '32rem',
            }}
            body={{
              text: `Last updated on ${prismicCmsLegal.data.last_updated}`,
              maxWidth: '52rem',
              maxWidthMobile: '27rem',
              overrideFontSize: '2.4rem',
            }}
            color={colors.generics.black}
          />
          <div className="content">
            <Markdown content={prismicCmsLegal.data.content.text} />
          </div>
        </Inner>
      </LegalContentContainer>
      <PhoneCTA buttonText="Get the App" />
    </GenericPageWrapper>
  );
}

export default withPrismicPreview(Legal);

export const legalQuery = graphql`
  query ($uid: String!) {
    prismicCmsLegal(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title
        last_updated(formatString: "MMMM Do, YYYY")
        content {
          text
        }
      }
    }
  }
`;
