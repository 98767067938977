import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import _chunk from 'lodash/chunk';

import SEO from '../components/SEO';
import { Container, Inner } from '../components/Elements';
import FAQList from '../components/FAQList';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PhoneCTA from '../components/PhoneCTA';

const Wrapper = styled.div`
  padding-top: 8.8rem;
  display: flex;
  flex-direction: column;

  p {
    ${(props) => props.theme.font.p2};
  }

  .section-title {
    width: 100%;
    text-align: center;
    margin-top: 12.8rem;
    margin-bottom: 6.4rem;

    ${(props) => props.theme.mq.small`
      margin-top: 9.6rem;
      margin-bottom: 4.8rem;
    `};
  }

  .section-one {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12.8rem;

    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    .section-one-row {
      display: flex;
      width: 100%;
      gap: 8rem;
      align-items: center;
      margin-bottom: 6.4rem;

      ${(props) => props.theme.mq.medium`
      gap: 4rem;
    `};

      &:last-child {
        margin-bottom: 0;
      }

      ${(props) => props.theme.mq.small`
      flex-wrap: wrap;
      `};

      &:nth-child(even) {
        .section-one-image {
          order: 0;
        }
        .section-one-content {
          order: 1;
        }
      }
    }

    .section-one-image,
    .section-one-content {
      width: 50%;
      max-width: 53.6rem;
      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        margin-bottom: 2rem;
      }

      p {
        ${(props) => props.theme.font.p1};
        color: ${(props) => props.theme.colors.text.secondary};
      }

      ${(props) => props.theme.mq.small`
        width: 100%;
      `};
    }
    .section-one-image {
      text-align: center;
      ${(props) => props.theme.mq.small`
        order: 0;
      `};
      .section-one-image-inner {
        width: 100%;
        max-width: 45.2rem;
        margin: auto;
      }
    }
    .section-one-content {
      ${(props) => props.theme.mq.small`
        order: 1;
      `};
    }
  }

  .section-two {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12.8rem;

    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    .section-title {
      width: 100%;
      text-align: center;
      margin-top: 0;
      margin-bottom: 6.4rem;

      ${(props) => props.theme.mq.small`
      margin-bottom: 4.8rem;
    `};
    }

    .section-two-group {
      display: flex;
      width: 100%;
      gap: 2rem;
      align-items: center;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      ${(props) => props.theme.mq.medium`
        flex-wrap: wrap;
      `};

      &:nth-child(odd) {
        .large-item {
          order: 1;
        }
        .small-items {
          order: 0;
        }
      }

      .large-item,
      .small-items {
        width: 50%;

        ${(props) => props.theme.mq.medium`
            width: 100%;
        `};

        .emoji {
          font-size: 7.2rem;
          line-height: 7.2rem;
        }

        p {
          ${(props) => props.theme.font.p1};
          margin: 0;
        }

        a {
          color: ${(props) => props.theme.colors.purple.primary};
          text-decoration: underline;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          ${(props) => props.theme.font.highlight};
          margin-top: 2rem;
        }
      }

      .large-item {
        background: ${(props) => props.theme.colors.teal.primary};
        min-height: 100%;
        border-radius: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 4rem 2rem;

        ${(props) => props.theme.mq.medium`
            min-height: unset;
        `};
      }

      .small-items {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        ${(props) => props.theme.mq.medium`
            flex-direction: row;
        `};
        ${(props) => props.theme.mq.small`
            flex-wrap: wrap;
        `};

        .section-two-item {
          background: ${(props) => props.theme.colors.teal.primary};
          border-radius: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 4rem 2rem;

          ${(props) => props.theme.mq.medium`
            width: 50%;
        `};
          ${(props) => props.theme.mq.small`
            width: 100%;
        `};
        }
      }
    }
  }

  .faq-section {
    width: 100%;

    .section-title {
      width: 100%;
      text-align: center;
      margin-top: 0;
      margin-bottom: 6.4rem;

      ${(props) => props.theme.mq.small`
      margin-bottom: 4.8rem;
    `};
    }

    margin-bottom: 12.8rem;

    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};
  }
`;

WhyPool.propTypes = {
  data: PropTypes.object.isRequired,
};

function WhyPool({ data: { prismicCmsWhyPool: pageData } }) {
  const seoData = {
    title: pageData.data.title_tag,
    description: pageData.data.meta_description,
    image: pageData.data.og_image?.url || null,
    slug: '/',
  };

  const renderTitle = () => {
    return (
      <Container>
        <Inner>
          <div className="section-title">
            <h1>{pageData.data.title}</h1>
          </div>
        </Inner>
      </Container>
    );
  };
  const renderSectionOne = () => {
    return (
      <Container>
        <Inner>
          <div className="section-one">
            {pageData.data.section_1_items.map((s, i) => {
              return (
                <div className="section-one-row" key={i}>
                  <div className="section-one-content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: s.content.html,
                      }}
                    />
                  </div>
                  <div className="section-one-image">
                    <div className="section-one-image-inner">
                      <GatsbyImage
                        alt={s.image.alt || s.title1}
                        image={s.image.gatsbyImageData}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Inner>
      </Container>
    );
  };

  const renderSectionTwoItem = (s) => {
    if (!s) return null;
    return (
      <div className="section-two-item">
        <div className="section-two-item-inner">
          <span className="emoji">{s.emoji}</span>
          <div
            dangerouslySetInnerHTML={{
              __html: s.content.html,
            }}
          />
        </div>
      </div>
    );
  };

  const renderSectionTwo = () => {
    const groups = _chunk(pageData.data.section_2_items, 3);
    return (
      <Container>
        <Inner>
          <div className="section-two">
            <div className="section-title">
              <h2>{pageData.data.section_2_title}</h2>
            </div>
            {groups.map((group, i) => {
              return (
                <div key={i} className="section-two-group">
                  <div className="large-item">
                    {renderSectionTwoItem(group[0])}
                  </div>
                  <div className="small-items">
                    {renderSectionTwoItem(group[1])}
                    {renderSectionTwoItem(group[2])}
                  </div>
                </div>
              );
            })}
          </div>
        </Inner>
      </Container>
    );
  };
  const renderSectionThree = () => {
    return (
      <Container>
        <Inner>
          <div className="faq-section">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className="faq-component-wrapper">
              <FAQList faqs={pageData.data.faqs} />
            </div>
          </div>
        </Inner>
      </Container>
    );
  };

  return (
    <Wrapper>
      <SEO {...seoData} />
      {renderTitle()}
      {renderSectionOne()}
      {renderSectionTwo()}
      {renderSectionThree()}
      <PhoneCTA
        buttonText="Get the App"
        signUpBonusBoolean={pageData.data.sign_up_bonus_boolean}
      />
    </Wrapper>
  );
}

export default withPrismicPreview(WhyPool);

export const whyPoolQuery = graphql`
  {
    prismicCmsWhyPool {
      _previewable
      data {
        # SEO FIELDS
        title_tag
        meta_description
        og_image {
          url
        }
        # PAGE DATA
        title
        sign_up_bonus_boolean
        section_1_items {
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          content {
            html
          }
        }
        section_2_title
        section_2_items {
          emoji
          content {
            html
          }
        }
        faqs {
          question
          answer
        }
      }
    }
  }
`;
