import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Reboot from '../style/reboot';
import Global from '../style/global';
import { useStaticQuery, graphql } from 'gatsby';

import GlobalRichSnippets from '../components/GlobalRichSnippets';
import { theme } from '../style/theme';

const SiteWrapper = styled.div`
  background: ${({ theme }) => theme.colors.elements.background};
`;

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query FooterCta {
      allPrismicCmsGlobalData {
        edges {
          node {
            id
            data {
              footer_cta_text
              footer_cta_link {
                url
              }
            }
          }
        }
      }
    }
  `);

  const footerCta = data.allPrismicCmsGlobalData.edges[0].node.data;
  const footerCtaText = footerCta.footer_cta_text;
  const footerCtaLink = footerCta.footer_cta_link.url;

  return (
    <ThemeProvider theme={theme}>
      <SiteWrapper>
        <GlobalRichSnippets />
        <Reboot />
        <Global />
        <Header location={location} light />
        {children}
        <Footer cta={
          footerCtaText && footerCtaLink
            ? { text: footerCtaText, link: footerCtaLink }
            : null
        
        } />
      </SiteWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Layout;
