import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Container as ContainerElement,
  Inner as InnerElement,
  GenericPageWrapper,
} from '../components/Elements';
import SectionTitleBody from '../components/SectionTitleBody';
import SEO from '../components/SEO';
import FAQList from '../components/FAQList';

import { colors } from '../consts/style';
import PhoneCTA from '../components/PhoneCTA';
import BackgroundImage from '../components/BackgroundImage';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const inputCss = css`
  margin-top: 2rem;
  background: transparent;
  padding: 0;
  .phone-title {
    display: none;
  }
`;

const Container = styled(ContainerElement)`
  overflow: hidden;
  margin-bottom: 12rem;
`;

const Inner = styled(InnerElement)`
  flex-direction: column;

  .blog-card {
    background: white;
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};

    ${(props) => props.theme.mq.medium`
      margin-left: -4rem;
      margin-right: -4rem;
      width: calc(100% + 8rem);
      `}

    ${(props) => props.theme.mq.small`
      margin-left: -2rem;
      margin-right: -2rem;
      width: calc(100% + 4rem);
      `}

    .blog-card-inner {
      width: 100%;
      display: flex;

      ${(props) => props.theme.mq.small`
          flex-direction: column;
      `}

      .blog-card-details {
        padding: 8rem 6.4rem;
        width: 50%;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border: 0.5px solid #e6e3df;

        ${(props) => props.theme.mq.medium`
          padding: 4rem 3.2rem;
        `}
        h3 {
          margin-bottom: 2rem;
        }
        p {
          margin-bottom: 3.2rem;
          ${(props) => props.theme.font.p2};
        }
        a {
          ${(props) => props.theme.font.buttonMediumPrimary}
        }

        ${(props) => props.theme.mq.small`
         order: 1;
         width: 100%;
        `}
      }
      .blog-card-image {
        width: 50%;
        background: ${(props) => props.theme.colors.purple.primary};
        position: relative;

        ${(props) => props.theme.mq.small`
         order: 0;
         height: 28.1rem;
         width: calc(100% + 4rem);
        `}
      }
    }
  }

  .faq-section {
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};

    .faq-component-wrapper {
      margin-top: 6rem;
    }
  }

  .section-3 {
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};
  }

  .section-2 {
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};

    .list-items {
      margin-top: 6.4rem;
      display: flex;
      justify-content: space-between;

      .section-row {
        max-width: 30%;
        width: 33rem;

        .section-image-content {
          margin-bottom: 2rem;
        }

        .section-row-content {
          text-align: center;
          padding: 2rem;
          h4 {
            ${(props) => props.theme.font.genericH3}
            margin-bottom: 1.2rem;
          }
          p {
            ${(props) => props.theme.font.p2}
          }
        }
      }

      ${(props) => props.theme.mq.medium`
        flex-wrap: wrap;
        .section-row {
          width: 100%;
          max-width: 100%;
          margin-bottom: 6.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .section-image-content, .section-row-content {
            width: calc(50% - 1rem);
          }

          &:nth-child(odd) {
            .section-image-content {
              order: 1;
            }
            .section-row-content {
              order: 0;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      `}

      ${(props) => props.theme.mq.small`
        .section-row {
          flex-wrap: wrap;

          .section-image-content, .section-row-content {
            width: 100%;
          }

            .section-image-content {
              order: 0 !important;
            }
            .section-row-content {
              order: 1 !important;
            }


          &:last-child {
            margin: 0;
          }
        }
      `}
    }
  }

  .section-1 {
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 96.8rem;

    .section-row {
      margin-top: 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(even) {
        .section-image-content {
          order: 0;
        }
        .section-row-content {
          order: 1;
        }
      }

      .section-row-content,
      .section-image-content {
        width: 100%;
        max-width: 42rem;
      }

      .section-row-content {
        text-align: center;
        h4 {
          ${(props) => props.theme.font.genericH3}
          margin-bottom: 1.2rem;
        }
        p {
          ${(props) => props.theme.font.p2}
        }
      }

      ${(props) => props.theme.mq.small`
      flex-wrap: wrap;
        .section-image-content {
          order: 0;
          margin-bottom: 2rem;
        }
        .section-row-content {
          order: 1;
        }
        .section-row-content, .section-image-content {
          max-width: 100%;
        }
      `}
    }

    .rounded-cta {
      margin-top: 6rem;
      text-align: center;

      .rounded-cta-inner {
        padding: 2.4rem 4rem;
        border: 0.1rem solid black;
        max-width: 40rem;
        margin: 0 auto;
        border-radius: 2rem;

        h3 {
          ${(props) => props.theme.font.cardTitle};
          margin-bottom: 1.2rem;
        }
        p {
          ${(props) => props.theme.font.p2};
        }
      }
    }
  }
`;

Coliving.propTypes = { data: PropTypes.object.isRequired };

function Coliving({
  data: {
    prismicCmsPoolMoney: { data: pageData },
  },
}) {
  const seoData = {
    title: pageData.title_tag,
    description: pageData.meta_description,
    image: pageData.og_image?.url || null,
    slug: '/pool-money',
  };

  function renderSection1Items() {
    return pageData.section_1_items.map((s) => {
      return (
        <div className="section-row" key={s.title1}>
          <div className="section-row-content">
            <h4>{s.title1}</h4>
            <p>{s.body1}</p>
          </div>
          <div className="section-image-content">
            <GatsbyImage
              alt={s.image.alt || s.title1}
              image={s.image.gatsbyImageData}
            />
          </div>
        </div>
      );
    });
  }
  function renderSection2Items() {
    return pageData.section_2_items.map((s) => {
      return (
        <div className="section-row" key={s.title1}>
          <div className="section-image-content">
            <GatsbyImage
              alt={s.image.alt || s.title1}
              image={s.image.gatsbyImageData}
            />
          </div>
          <div className="section-row-content">
            <h4>{s.title1}</h4>
            <p>{s.body1}</p>
          </div>
        </div>
      );
    });
  }

  return (
    <GenericPageWrapper>
      <SEO {...seoData} />
      <Container>
        <Inner>
          <SectionTitleBody
            title={{
              text: pageData.title,
              element: 'h1',
              maxWidth: '102.4rem',
              maxWidthMobile: '36rem',
            }}
            body={{
              text: pageData.body,
              maxWidth: '65rem',
              maxWidthMobile: '100%',
            }}
            color={colors.generics.black}
          />
          <PhoneCTA additionalStyles={inputCss} />
          <div className="section-1">
            <SectionTitleBody
              title={{
                text: pageData.section_1_title,
                element: 'h2',
                maxWidth: '102.4rem',
                maxWidthMobile: '100%',
              }}
              color={colors.generics.black}
            />
            <div className="rounded-cta">
              <div className="rounded-cta-inner">
                <h3>{pageData.section_1_blurb_title}</h3>
                <p>{pageData.section_1_blurb_body}</p>
              </div>
            </div>
            <div className="list-items">{renderSection1Items()}</div>
          </div>
          <div className="section-2">
            <SectionTitleBody
              title={{
                text: pageData.section_2_title1,
                element: 'h2',
                maxWidth: '102.4rem',
                maxWidthMobile: '100%',
              }}
              color={colors.generics.black}
            />
            <div className="list-items">{renderSection2Items()}</div>
          </div>
          <div className="section-3">
            <SectionTitleBody
              title={{
                text: pageData.section_3_title,
                element: 'h2',
                maxWidth: '64rem',
                maxWidthMobile: '44rem',
              }}
              body={{
                text: pageData.section_3_body,
                maxWidth: '65rem',
                maxWidthMobile: '100%',
              }}
              cta={{
                text: pageData.section_3_button_text,
                to: pageData.section_3_button_link,
              }}
              color={colors.generics.black}
            />
            <div className="list-items"></div>
          </div>
          <div className="blog-card">
            <div className="blog-card-inner">
              <div className="blog-card-details">
                <h3>{pageData.blog_title}</h3>
                <p>{pageData.blog_body}</p>
                <a target="blank" href={pageData.blog_link}>
                  Read the Post
                </a>
              </div>
              <div className="blog-card-image">
                <BackgroundImage
                  image={pageData.blog_image.gatsbyImageData}
                  alt={pageData.blog_title}
                />
              </div>
            </div>
          </div>
          <div className="faq-section">
            <SectionTitleBody
              title={{
                text: 'FAQ',
                element: 'h2',
                maxWidth: '72rem',
                maxWidthMobile: '100%',
              }}
              color={colors.generics.black}
            />
            <div className="faq-component-wrapper">
              <FAQList faqs={pageData.faqs} />
            </div>
          </div>
        </Inner>
      </Container>
      {/* <PhoneCTA buttonText="Get the App"/> */}
    </GenericPageWrapper>
  );
}

export default withPrismicPreview(Coliving);

export const poolQuery = graphql`
  {
    prismicCmsPoolMoney {
      _previewable
      data {
        title_tag
        meta_description
        og_image {
          url
        }
        title
        body
        section_1_title
        section_1_blurb_title
        section_1_blurb_body
        section_1_items {
          title1
          body1
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        section_2_title1
        section_2_items {
          title1
          body1
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        section_3_title
        section_3_body
        section_3_button_text
        section_3_button_link
        blog_title
        blog_body
        blog_link
        blog_image {
          url
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        faqs {
          question
          answer {
            html
            text
          }
        }
      }
    }
  }
`;
