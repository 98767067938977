import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../consts/style';

const SVG = styled.svg`
  transition: all 0.3s ease;
`;

TikTok.propTypes = { fill: PropTypes.string };

export default function TikTok({ fill = colors.elements.background }) {
  return (
    <SVG
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.76863 5.87766C5.76863 6.8115 5.76863 7.72615 5.76863 8.6568C5.624 8.63441 5.48901 8.60883 5.35081 8.59284C4.29342 8.4777 3.29067 9.09493 2.91785 10.0927C2.54503 11.0937 2.89857 12.2355 3.78562 12.8239C4.53126 13.3196 5.33153 13.3836 6.13502 12.987C6.9353 12.5904 7.38846 11.922 7.42382 11.0298C7.45917 10.1023 7.43989 9.17489 7.43989 8.24744C7.43989 5.56105 7.43989 2.87466 7.43989 0.188274C7.43989 0.130708 7.43989 0.0731426 7.43989 0.00278473C8.36551 0.00278473 9.27827 0.00278473 10.1942 0.00278473C10.2167 0.204264 10.2264 0.405743 10.265 0.604024C10.5735 2.27023 11.5955 3.27442 13.2186 3.69977C13.4403 3.75733 13.6718 3.78292 13.8935 3.83729C13.9353 3.84688 13.9996 3.91084 13.9996 3.94922C14.006 4.79671 14.0028 5.6442 14.0028 6.49169C14.0028 6.50129 13.9964 6.51088 13.9835 6.54606C12.6111 6.53007 11.3738 6.09193 10.2135 5.30521C10.2135 5.39155 10.2135 5.45232 10.2135 5.51308C10.2135 7.28802 10.2264 9.06295 10.2103 10.8379C10.1942 12.8047 9.34255 14.3302 7.63594 15.3184C4.42841 17.1797 0.327399 15.0338 0.0188593 11.3528C-0.212546 8.58964 1.82832 6.13991 4.60518 5.85847C4.92336 5.82649 5.24797 5.85208 5.56615 5.85208C5.63043 5.85208 5.69149 5.86487 5.76863 5.87766Z"
        fill={fill}
        fillOpacity="0.95"
      />
    </SVG>
  );
}
