

import React from 'react';
import PropTypes from 'prop-types';

ParagraphOrHtml.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ html: PropTypes.string })])
}

export default function ParagraphOrHtml({ text = '' }) {
    if (typeof text?.html === 'string') {
        return <div dangerouslySetInnerHTML={{ __html: text.html }} />
    }
    return <p>{text}</p>
}
