import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BackgroundImage from '../components/BackgroundImage';
import ParagraphOrHtml from './ParagraphOrHtml';

const BlogCardWrapper = styled.div`
background: white;

${(props) => props.theme.mq.medium`
    margin-left: -4rem;
    margin-right: -4rem;
    width: calc(100% + 8rem);
    `}

${(props) => props.theme.mq.small`
    margin-left: -2rem;
    margin-right: -2rem;
    width: calc(100% + 4rem);
    `}

.blog-card-inner {
    width: 100%;
    display: flex;

    ${(props) => props.theme.mq.small`
        flex-direction: column;
    `}

    .blog-card-details {
    padding: 8rem 6.4rem;
    width: 50%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border: 0.5px solid #e6e3df;

    ${(props) => props.theme.mq.medium`
        padding: 4rem 3.2rem;
    `}
    h3 {
        margin-bottom: 2rem;
    }
    p {
        margin-bottom: 3.2rem;
        ${(props) => props.theme.font.p2};
    }
    a {
        ${(props) => props.theme.font.buttonMediumPrimary}
    }

    ${(props) => props.theme.mq.small`
        order: 1;
        width: 100%;
    `}
    }
    .blog-card-image {
    width: 50%;
    background: ${(props) => props.theme.colors.purple.primary};
    position: relative;

    ${(props) => props.theme.mq.small`
        order: 0;
        height: 28.1rem;
        width: calc(100% + 4rem);
    `}
    }    
  }
`

BlogCard.propTypes = {
    data: PropTypes.shape({
        blog_title: PropTypes.string,
        blog_body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        blog_link: PropTypes.string,
        blog_image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
            gatsbyImageData: PropTypes.object,
        }),
    }),
};

export default function BlogCard({ data: { blog_title, blog_body, blog_link, blog_image } }) {
    if (!blog_title || !blog_link) {
        return null;
    }
    return (
        <BlogCardWrapper className="blog-card">
            <div className="blog-card-inner">
                <div className="blog-card-details">
                    <h3>{blog_title}</h3>
                    <ParagraphOrHtml text={blog_body} />
                    <a target="blank" href={blog_link}>
                        Read the Post
                    </a>
                </div>
                {<div className="blog-card-image">
                    <BackgroundImage
                        image={blog_image.gatsbyImageData}
                        alt={blog_title}
                    />
                </div>}
            </div>
        </BlogCardWrapper>
    );
}
