import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { font, mq } from '../consts/style';
import ParagraphOrHtml from './ParagraphOrHtml';

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;

  .cta {
    margin-top: 2.8rem;
    ${(props) => props.noBody && 'margin-top: 0;'}
    a {
      ${props => props.theme.font.buttonLargePrimary};
      font-size: 2.4rem;
    }
  }
`;

const Title = styled.h2`
  max-width: ${(props) => props.maxWidth};
  margin-bottom: 2rem;
  position: relative;

  ${(props) => props.withSubtitle && 'margin-bottom: 2rem;'}

  .blurb {
    position: absolute;
    z-index: -1;

    &.one {
      top: 0;
      left: 0;
      transform: translate(-40%, -65%);
    }
    &.two {
      bottom: 0;
      right: 0;
      transform: translate(30%, 40%);
    }

    ${mq.medium`
      &.one {
        transform: translate(-45%, -25%);
        width: 15rem;
      }
      &.two {
        transform: translate(50%, 50%);
        width: 14rem;
      }
    `}
  }

  ${mq.medium`
    max-width: ${(props) => props.maxWidthMobile}
  `}
`;
const Body = styled.div`
  max-width: ${(props) => props.maxWidth};
  ${(props) => props.theme.font.p1};

  ${(props) =>
    props.overrideFontSize && `font-size: ${props.overrideFontSize};`}

  ${mq.medium`
    max-width: ${(props) => props.maxWidthMobile}
  `}
`;

const Subtitle = styled.p`
  max-width: ${(props) => props.maxWidth};
  font-size: 8rem;
  line-height: 6.8rem;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 2.4rem;
  ${font.founders}

  ${mq.medium`
        font-size: 4.6rem;
        line-height: 4rem;
      `}

  ${(props) =>
    props.overrideFontSize && `font-size: ${props.overrideFontSize};`}

  ${mq.medium`
    max-width: ${(props) => props.maxWidthMobile}
  `}
`;

SectionTitleBody.propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object,
  body: PropTypes.object,
  cta: PropTypes.object,
  color: PropTypes.string,
  showBlurbs: PropTypes.bool,
};

SectionTitleBody.defaultProps = {
  title: {
    text: 'Title',
    element: 'h2',
  },
};

export default function SectionTitleBody({
  title,
  subtitle,
  body,
  cta,
  color,
  showBlurbs,
}) {
  return (
    <Wrapper color={color} noBody={!body}>
      <Title
        as={title.element}
        maxWidth={title.maxWidth}
        maxWidthMobile={title.maxWidthMobile}
        withSubtitle={Boolean(subtitle)}
      >
        {title.text}
        {showBlurbs && (
          <Fragment>
            <div className="blurb one">
              <StaticImage
                placeholder="blurred"
                alt="Blurb 1"
                src={'../images/blurb1-v2.png'}
                width={200}
              />
            </div>
            <div className="blurb two">
              <StaticImage
                placeholder="blurred"
                alt="Blurb 2"
                src={'../images/blurb2.png'}
                width={180}
              />
            </div>
          </Fragment>
        )}
      </Title>
      {subtitle && (
        <Subtitle
          as={subtitle.element}
          maxWidth={subtitle.maxWidth}
          maxWidthMobile={subtitle.maxWidthMobile}
          overrideFontSize={subtitle.overrideFontSize}
        >
          {subtitle.text}
        </Subtitle>
      )}
      {body && (
        <Body
          as={body.element}
          maxWidth={body.maxWidth}
          maxWidthMobile={body.maxWidthMobile}
          overrideFontSize={body.overrideFontSize}
        >
          <ParagraphOrHtml text={body.text} />
        </Body>
      )}
      {/* This wrapping div is silly, but the internet says it fixes the child not rendering with its class.
      https://stackoverflow.com/questions/61763216/gatsby-not-adding-classname-to-html-element-after-gatsby-build */}
      <div>
        {cta && cta.to && (
          <div className="cta">
            {cta.to.includes('http') ? (
              <a target="blank" href={cta.to}>{cta.text}</a>
            ) : (
              <Link to={cta.to} title={cta.text}>
                {cta.text}
              </Link>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}
