/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { colors, font, mq } from '../consts/style';
import { createFaqStructuredData } from '../utils/richSnippets';
import Caret from './icons/Caret';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .faq-block {
    position: relative;
    border-top: 0.1rem solid ${colors.generics.black};
    padding: 3rem 4rem 3rem 0;
    &:last-child {
      border-bottom: 0.1rem solid ${colors.generics.black};
    }
  }

  .faq-inner {
    width: 100%;

    font-size: 2.4rem;
    line-height: 2.6rem;

    ${mq.medium`
      font-size: 2rem;
      line-height: 2.2rem;
    `}

    a {
      font-weight: 500;
      color: ${colors.generics.black};
    }

    .question {
      ${font.faqTitle}
    }

    .answer {
      margin-top: 2rem;
      width: 60%;
      ${font.faqBody}

      ${mq.large`
        width: 80%;
      `}
      ${mq.medium`
        width: 100%;
      `}
    }
  }

  button {
    height: 40px;
    width: 40px;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: 2rem;
    right: 0;

    img {
      width: 40px;
    }

    ${mq.medium`
    top: 3rem;
    height: 30px;
    width: 30px;

    img {
      width: 30px;
    }
    `}
  }
`;

FAQList.propTypes = {
  faqs: PropTypes.array,
};

export default function FAQList({ faqs }) {
  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">
          {createFaqStructuredData(faqs)}
        </script>
      </Helmet>
      <Wrapper>
        {faqs && faqs.map((f, i) => <FAQBlock key={i} {...f} />)}
      </Wrapper>
    </Fragment>
  );
}

FAQBlock.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
function FAQBlock({ question, answer }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="faq-block">
      <div className="faq-inner">
        <div className="question" onClick={() => setOpen(!open)}>
          <span>{question}</span>
          <button
            aria-describedby="dropdownInfo"
            aria-label={`Toggle dropdown - ${question}`}
            onClick={() => setOpen(!open)}
          >
            <Caret open={open} />
          </button>
        </div>

        <div aria-hidden={!open}>
          {open && !answer?.html && (
            <div id="dropdownInfo" className="answer">
              <span>{answer}</span>
            </div>
          )}
          {open && answer?.html && (
            <div
              id="dropdownInfo"
              className="answer"
              dangerouslySetInnerHTML={{ __html: answer?.html }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
