import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  Container as ContainerElement,
  Inner as InnerElement,
  GenericPageWrapper,
} from '../components/Elements';
import SectionTitleBody from '../components/SectionTitleBody';
import SEO from '../components/SEO';
import FAQList from '../components/FAQList';

import { colors } from '../consts/style';
import PhoneCTA from '../components/PhoneCTA';
import ParagraphOrHtml from '../components/ParagraphOrHtml';
import BlogCard from '../components/BlogCard';
import SafetyAndSecurity from '../components/SafetyAndSecurity';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { setAppStoreUrl } from '../utils/getAppStoreUrl';
import Banner from '../components/Banner';

const inputCss = css`
  margin-top: 2rem;
  background: transparent;
  padding: 0;
  .phone-title {
    display: none;
  }
`;

const Container = styled(ContainerElement)`
  overflow: hidden;
  margin-bottom: 12rem;
`;

const Inner = styled(InnerElement)`
  flex-direction: column;

  .faq-section {
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};

    .faq-component-wrapper {
      margin-top: 6rem;
    }
  }

  .section-2 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};

    .list-items {
      margin-top: 6.4rem;
      display: flex;
      justify-content: space-between;

      .section-row {
        max-width: 30%;
        width: 33rem;

        .section-image-content {
          margin-bottom: 2rem;
        }

        .section-row-content {
          text-align: center;
          padding: 2rem;
          h4 {
            ${(props) => props.theme.font.genericH3}
            margin-bottom: 1.2rem;
          }
          p {
            ${(props) => props.theme.font.p2}
          }
        }
      }

      ${(props) => props.theme.mq.medium`
        flex-wrap: wrap;
        .section-row {
          width: 100%;
          max-width: 100%;
          margin-bottom: 6.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .section-image-content, .section-row-content {
            width: calc(50% - 1rem);
          }

          &:nth-child(odd) {
            .section-image-content {
              order: 1;
            }
            .section-row-content {
              order: 0;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      `}

      ${(props) => props.theme.mq.small`
        .section-row {
          flex-wrap: wrap;

          .section-image-content, .section-row-content {
            width: 100%;
          }

            .section-image-content {
              order: 0 !important;
            }
            .section-row-content {
              order: 1 !important;
            }


          &:last-child {
            margin: 0;
          }
        }
      `}
    }
  }

  .section-1 {
    margin-top: 12.8rem;
    ${(props) => props.theme.mq.small`margin-top: 9.6rem;`};
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 96.8rem;

    .section-row {
      margin-top: 6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(even) {
        .section-image-content {
          order: 0;
        }
        .section-row-content {
          order: 1;
        }
      }

      .section-row-content,
      .section-image-content {
        width: 100%;
        max-width: 42rem;
      }

      .section-row-content {
        text-align: center;
        h4 {
          ${(props) => props.theme.font.genericH3}
          margin-bottom: 1.2rem;
        }
        p {
          ${(props) => props.theme.font.p2}
        }
      }

      ${(props) => props.theme.mq.small`
      flex-wrap: wrap;
        .section-image-content {
          order: 0;
          margin-bottom: 2rem;
        }
        .section-row-content {
          order: 1;
        }
        .section-row-content, .section-image-content {
          max-width: 100%;
        }
      `}
    }

    .rounded-cta {
      margin-top: 6rem;
      text-align: center;

      .rounded-cta-inner {
        padding: 2.4rem 4rem;
        border: 0.1rem solid black;
        max-width: 40rem;
        margin: 0 auto;
        border-radius: 2rem;

        h3 {
          ${(props) => props.theme.font.cardTitle};
          margin-bottom: 1.2rem;
        }
        p {
          ${(props) => props.theme.font.p2};
        }
      }
    }
  }
`;

LandingPageTypeB.propTypes = { data: PropTypes.object.isRequired };

function LandingPageTypeB({
  data: {
    prismicCmsLandingPageTypeB: { data, uid },
  },
}) {
  const seoData = {
    title: data.title_tag,
    description: data.meta_description,
    image: data.og_image?.url || null,
    slug: `/${uid}`,
  };

  function renderSection1Items() {
    return data.section_1_items.map((s) => {
      return (
        <div className="section-row" key={s.title1}>
          <div className="section-row-content">
            <h4>{s.title1}</h4>
            <ParagraphOrHtml text={s.body1} />
          </div>
          <div className="section-image-content">
            <GatsbyImage
              alt={s.image.alt || s.title1}
              image={s.image.gatsbyImageData}
            />
          </div>
        </div>
      );
    });
  }
  function renderSection2Items() {
    return data.section_2_items.map((s) => {
      return (
        <div className="section-row" key={s.title1}>
          <div className="section-image-content">
            <GatsbyImage
              alt={s.image.alt || s.title1}
              image={s.image.gatsbyImageData}
            />
          </div>
          <div className="section-row-content">
            <h4>{s.title1}</h4>
            <ParagraphOrHtml text={s.body1} />
          </div>
        </div>
      );
    });
  }

  setAppStoreUrl(data.app_store_link);

  return (
    <GenericPageWrapper>
      <SEO {...seoData} />
      {data.banner_text && <Banner text={data.banner_text} />}
      <Container>
        <Inner>
          <SectionTitleBody
            title={{
              text: data.title,
              element: 'h1',
              maxWidth: '102.4rem',
              maxWidthMobile: '36rem',
            }}
            body={{
              text: data.body,
              maxWidth: '65rem',
              maxWidthMobile: '100%',
            }}
            color={colors.generics.black}
          />
          <PhoneCTA
            additionalStyles={inputCss}
            signUpBonusBoolean={data.sign_up_bonus_boolean}
          />
          <div className="section-1">
            <SectionTitleBody
              title={{
                text: data.section_1_title,
                element: 'h2',
                maxWidth: '102.4rem',
                maxWidthMobile: '100%',
              }}
              color={colors.generics.black}
            />
            <div className="rounded-cta">
              <div className="rounded-cta-inner">
                <h3>{data.section_1_blurb_title}</h3>
                <ParagraphOrHtml text={data.section_1_blurb_body} />
              </div>
            </div>
            <div className="list-items">{renderSection1Items()}</div>
          </div>
          <div className="section-2">
            <SectionTitleBody
              title={{
                text: data.section_2_title1,
                element: 'h2',
                maxWidth: '102.4rem',
                maxWidthMobile: '100%',
              }}
              color={colors.generics.black}
            />
            <div className="list-items">{renderSection2Items()}</div>
          </div>

          <BlogCard data={data} />
          <SafetyAndSecurity data={data} />
          <div className="faq-section">
            <SectionTitleBody
              title={{
                text: 'FAQ',
                element: 'h2',
                maxWidth: '72rem',
                maxWidthMobile: '100%',
              }}
              color={colors.generics.black}
            />
            <div className="faq-component-wrapper">
              <FAQList faqs={data.faqs} />
            </div>
          </div>
        </Inner>
        <PhoneCTA
          buttonText="Get the App"
          signUpBonusBoolean={data.sign_up_bonus_boolean}
        />
      </Container>
    </GenericPageWrapper>
  );
}

export default withPrismicPreview(LandingPageTypeB);

export const landingPageTypeBQuery = graphql`
  query ($uid: String!) {
    prismicCmsLandingPageTypeB(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        title_tag
        meta_description
        og_image {
          url
        }
        title
        body {
          html
        }
        banner_text
        app_store_link
        sign_up_bonus_boolean
        section_1_title
        section_1_blurb_title
        section_1_blurb_body {
          html
        }
        section_1_items {
          title1
          body1 {
            html
          }
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        section_2_title1
        section_2_items {
          title1
          body1 {
            html
          }
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        security_and_safety_title
        security_and_safety_items {
          body {
            html
          }
          image {
            url
            alt
          }
        }
        blog_title
        blog_body {
          html
        }
        blog_link
        blog_image {
          url
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        faqs {
          question
          answer {
            html
            text
          }
        }
      }
    }
  }
`;
