import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../components/SEO';
import FAQList from '../components/FAQList';
import PhoneCTA from '../components/PhoneCTA';
import { Container, Inner } from '../components/Elements';
import ParagraphOrHtml from '../components/ParagraphOrHtml';
import BlogCard from '../components/BlogCard';
import SafetyAndSecurity from '../components/SafetyAndSecurity';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { setAppStoreUrl } from '../utils/getAppStoreUrl';
import Banner from '../components/Banner';

import { font } from '../consts/style';

const Wrapper = styled.div`
  padding-top: 8.8rem;

  ${Inner} {
    flex-direction: column;
  }

  .section-hero {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12.8rem;
    text-align: center;
    justify-content: center;

    .section-info {
      z-index: 1;
      max-width: 640px;
      flex: 1;
      flex-shrink: 0;
      h1 {
        ${(props) => props.theme.font.j1};
        margin-bottom: 2rem;
      }
      p {
        ${(props) => props.theme.font.p1};
        margin-bottom: 4rem;
      }
      button {
        ${(props) => props.theme.font.buttonLargePrimary};
        margin-bottom: 0.8rem;
      }
    }

    .section-image {
      flex: 1;
      padding-left: 4rem;

      .section-image-inner {
        text-align: center;
      }
    }

    ${(props) => props.theme.mq.large`
      flex-direction: column;

      .section-info {
        order: 1;
        width: 100%;
        padding: 0;
        text-align: center;

        h1 {
          max-width: 60rem;
          margin: auto;
          margin-bottom: 2rem;
        }
      }
      .section-image {
        padding: 0;
        order: 0;
        margin-bottom: 2rem;

        .section-image-inner {
          width: 27.5rem;
        }
      }
    `}

    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `}
  }

  .section-title {
    width: 100%;
    text-align: center;
    margin-bottom: 6.4rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 4.8rem;
    `}
  }

  .section-one {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    .list-items {
      margin-top: 6.4rem;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }

      .section-row {
        max-width: 30%;
        width: 33rem;

        .section-image-content {
          margin-bottom: 2rem;
          text-align: center;

          .section-image-content-inner {
            text-align: center;
            max-width: 32rem;
            margin: auto;
          }

          ${(props) => props.theme.mq.small`
            width: 100%;
          `};
        }

        .section-row-content {
          text-align: center;
          padding-top: 2rem;
          h4 {
            ${(props) => props.theme.font.genericH3}
            margin-bottom: 1.2rem;
          }
          p {
            ${(props) => props.theme.font.p2}
          }
        }
      }

      ${(props) => props.theme.mq.large`
        flex-wrap: wrap;
        .section-row {
          width: 100%;
          max-width: 100%;
          margin-bottom: 6.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;


          .section-image-content, .section-row-content {
            width: calc(50% - 4rem);
            text-align: left;
          }

          &:nth-child(even) {
            .section-image-content {
              order: 1;
            }
            .section-row-content {
              order: 0;
              padding: 0;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      `}

      ${(props) => props.theme.mq.medium`
        .section-row {
          .section-image-content, .section-row-content {
            width: calc(50% - 2rem);
            text-align: left;
          }
        }
      `}

      ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;

        .section-row {
          flex-wrap: wrap;

          .section-image-content, .section-row-content {
            width: 100%;
          }

            .section-image-content {
              order: 0 !important;
            }
            .section-row-content {
              order: 1 !important;
              text-align: center;
            }


          &:last-child {
            margin: 0;
          }
        }
      `}
    }
  }

  .section-two {
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};

    .section-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6.4rem;

      ${(props) => props.theme.mq.large`
      justify-content: space-between;
      `};

      ${(props) => props.theme.mq.small`
        flex-wrap: wrap;
      `};

      &:last-child {
        margin: 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        color: ${(props) => props.theme.colors.text.highlight};
        ${(props) => props.theme.font.highlight};
      }

      p {
        margin-bottom: 4rem;
        ${(props) => props.theme.font.p2};
      }

      .section-row-content,
      .section-image-content {
        width: 100%;
        max-width: 42rem;

        ${(props) => props.theme.mq.large`
        width: calc(50% - 4rem);
        `};
        ${(props) => props.theme.mq.medium`
        width: calc(50% - 2rem);
        `};
        ${(props) => props.theme.mq.small`
        width: 100%;
        max-width: none;
        `};
      }

      .section-image-wrapper {
        margin: 0 auto;
      }

      .section-image-content {
        text-align: center;
        ${(props) => props.theme.mq.small`
            margin: 0;
            margin-bottom: 4rem;
            width: 100%;
          `};
      }

      &:nth-child(even) {
        .section-row-content {
          order: 0;
          margin-right: 12.8rem;
          ${(props) => props.theme.mq.large`
            margin-right: 8rem;
          `};
          ${(props) => props.theme.mq.medium`
            margin-right: 4rem;
          `};
          ${(props) => props.theme.mq.small`
            order: 1;
            margin-right: 0;
          `};
        }
        .section-image-content {
          order: 1;
          ${(props) => props.theme.mq.small`
            order: 0;
          `};
        }
      }
      &:nth-child(odd) {
        .section-row-content {
          margin-left: 12.8rem;
          ${(props) => props.theme.mq.large`
            margin-left: 8rem;
          `};
          ${(props) => props.theme.mq.medium`
            margin-left: 4rem;
          `};
          ${(props) => props.theme.mq.small`
            margin-left: 0;
          `};
        }
      }
    }
  }

  .section-faqs {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem;
    ${(props) => props.theme.mq.small`
      margin-bottom: 9.6rem;
    `};
  }
`;

const phoneCtaStyles = css`
  background: transparent;
  padding: 0;

  .phone-title {
    display: none;
  }
  .phone-title {
    display: none;
  }
  .phone-input {
    justify-content: flex-start;
    form {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: center;
      input {
        width: 100%;
        max-width: 32rem;
        flex-shrink: 1;
      }
    }
  }
  .phone-legal,
  .phone-message {
    text-align: center;
    ${(props) => props.theme.mq.large`
      text-align: center;
    `};
  }
  ${Container} {
    padding: 0;
  }
`;

const SectionHero = styled.section`
  padding: 6.4rem 2rem;
  ${(props) => `background: url(${props.heroUrl}) top center;`}
  background-size: cover;
`;

LandingPageTypeA.propTypes = {
  data: PropTypes.object.isRequired,
};

function LandingPageTypeA({
  data: {
    prismicCmsLandingPageTypeA: { data, uid },
  },
}) {
  const seoData = {
    title: data.title_tag,
    description: data.meta_description,
    image: data.og_image?.url || null,
    slug: `/${uid}`,
  };

  const renderSectionOneItems = () => {
    return data.section_1_items.map((s) => {
      return (
        <div className="section-row" key={s.title}>
          <div className="section-image-content">
            <div className="section-image-content-inner">
              <GatsbyImage
                alt={s.image.alt || s.title}
                image={s.image.gatsbyImageData}
              />
            </div>
          </div>
          <div className="section-row-content">
            <h4>{s.title}</h4>
            <ParagraphOrHtml text={s.body} />
          </div>
        </div>
      );
    });
  };

  const renderSectionTwoItems = () => {
    return data.section_2_items.map((s) => {
      const getMaxWidth = () => {
        if (s.image_max_width !== undefined) {
          return {
            maxWidth: `${s.image_max_width}px`,
            width: '100%',
            margin: '0 auto',
          };
        }
        return { margin: '0 auto' };
      };
      return (
        <div className="section-row" key={s.image.url}>
          <div className="section-image-content">
            <div className="section-image-wrapper">
              <div style={getMaxWidth()}>
                <GatsbyImage
                  alt={s.image.alt || s.title}
                  image={s.image.gatsbyImageData}
                />
              </div>
            </div>
          </div>
          <div className="section-row-content">
            <div dangerouslySetInnerHTML={{ __html: s.content.html }}></div>
          </div>
        </div>
      );
    });
  };

  setAppStoreUrl(data.app_store_link);

  const onClickExternalLinkOpenNewTab = (url) => {
    window && window.open(url, '_blank');
  };
  return (
    <Wrapper>
      <SEO {...seoData} />
      {data.banner_text && <Banner text={data.banner_text} />}
      <Container>
        <Inner>
          <SectionHero heroUrl={data.hero_image.url} className="section-hero">
            <div className="section-info">
              <h1>{data.hero_title}</h1>
              <ParagraphOrHtml text={data.hero_body} />

              {data.cta_button_text && data.cta_link_button.url && (
                <button
                  onClick={() =>
                    onClickExternalLinkOpenNewTab(data.cta_link_button.url)
                  }
                >
                  {data.cta_button_text}
                </button>
              )}
            </div>
          </SectionHero>
          {data.section_1_items?.length ? (
            <section className="section-one">
              <div className="section-title">
                <h2>{data.section_1_title}</h2>
              </div>
              <div className="list-items">{renderSectionOneItems()}</div>
            </section>
          ) : null}
          {data.section_2_items?.length ? (
            <section className="section-two">
              <div className="section-title">
                <h3>{data.section_2_title}</h3>
              </div>
              {renderSectionTwoItems()}
            </section>
          ) : null}
          <BlogCard data={data} />

          <SafetyAndSecurity data={data} />
          <section className="section-faqs">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
            </div>
            <FAQList faqs={data.faqs} />
          </section>
        </Inner>
      </Container>
      <PhoneCTA
        buttonText="Get the App"
        signUpBonusBoolean={data.sign_up_bonus_boolean}
      />
    </Wrapper>
  );
}

export default withPrismicPreview(LandingPageTypeA);

export const landingPageTypeAQuery = graphql`
  query ($uid: String!) {
    prismicCmsLandingPageTypeA(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        meta_description
        og_image {
          url
        }
        title_tag
        app_store_link
        cta_button_text
        cta_link_button {
          url
        }
        banner_text
        hero_title
        sign_up_bonus_boolean
        hero_body {
          html
        }
        hero_image {
          url
        }
        section_1_title
        section_1_items {
          title
          body {
            html
          }
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }

        section_2_title
        section_2_items {
          content {
            html
          }
          image_max_width
          image {
            url
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }

        security_and_safety_title
        security_and_safety_items {
          body {
            html
          }
          image {
            url
            alt
          }
        }
        blog_title
        blog_body {
          html
        }
        blog_link
        blog_image {
          url
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        faqs {
          question
          answer {
            html
          }
        }
      }
    }
  }
`;
