import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import gfm from 'remark-gfm';

const Wrapper = styled(ReactMarkdown)`
  width: 100%;
`;

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
};

export default function Markdown({ content }) {
  return <Wrapper remarkPlugins={[gfm]}>{content}</Wrapper>;
}
