import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import breakpoints from '../consts/breakpoints';
import useWindowSize from '../hooks/useWindowSize';
import { colors, font, mq, zIndex } from '../consts/style';

import { Container, Inner } from './Elements';
import LogoMarkCompact from './icons/LogoMarkCompact';
import LogoMark from './icons/LogoMark';
import Twitter from './icons/Twitter';
import TikTok from './icons/TikTok';
import { TIKTOK_LINK, TWITTER_LINK } from '../consts/social';

const Wrapper = styled.footer`
  background: ${colors.generics.black};
  color: ${colors.elements.background};
  padding: 8rem 0;
  position: relative;
  z-index: ${zIndex.body};

  button {
    display: inline-block;
    margin-bottom:1.5em;
    ${(props) => props.theme.font.buttonMediumPrimary};
  }

  ${mq.medium`
    padding: 6.4rem 0;
  `}
  ${mq.small`
    padding: 4rem 0;
  `}
    ${Inner} {
    display: flex;
    flex-direction: column;

    .footer-logo {
      margin-bottom: 4rem;

      ${mq.small`
        text-align: center;
      `}
    }
    .footer-nav {
      display: flex;
      justify-content: space-between;

      ${mq.small`
        flex-wrap: wrap;
      `}

      .footer-nav-links {
        display: flex;
        width: 100%;
        flex-shrink: 1;

        ${mq.small`
          width: 100%;
          justify-content: center;
          text-align: center;
        `}

        .social-list {
          display: flex;

          ${mq.small`
            justify-content: center;
            text-align: center;
          `}

          li {
            margin-right: 1.2rem;
            &:last-child {
              margin: 0;
            }
          }
        }

        ul {
          padding: 0;
          list-style: none;
          width: 100%;
          max-width: 14.2rem;
          margin: 0;
          margin-right: 4rem;
          flex-shrink: 1;

          ${mq.small`
            margin-right: 2rem;

            &:last-child {
              margin: 0;
            }
          `}

          li {
            margin-bottom: 1.2rem;
            a {
              ${font.p3};
              margin: 0;
              color: white;
            }
            &:last-child {
              margin: 0;
            }
          }
        }
      }

      .footer-info-ctas{
        ${mq.small`
        justify-content: center;
        width:100%;
        text-align: center;
        button {
          margin: 0 auto;
          margin-top:0.5em;
        }
      `}


      }

      .footer-nav-legal {
        width: 100%;
        flex-shrink: 1;
        display: flex;
        justify-content: flex-end;

        p {
          max-width: 31.5rem;
          margin: 0;
          opacity: 0.75;
          ${font.p3}
        }

        ${mq.small`
          width: 100%;
          margin-top: 2rem;
          text-align: center;
          justify-content: center;
          p {
            margin: 0 auto;
            max-width: 33.5rem;
          }
        `}
      }
    }
  }
`;

const Footer = ({ cta }) => {
  const windowSize = useWindowSize(),
    isMobile = windowSize.width < breakpoints.mobile,
    LogoComponent = isMobile ? LogoMarkCompact : LogoMark;

  const navItems = [
    [
      { type: 'internal', title: 'FAQs', link: '/faqs' },
      { type: 'internal', title: 'Blog', link: '/blog' },
      {
        type: 'social',
        items: [
          { iconComponent: Twitter, link: TWITTER_LINK, name: 'twitter' },
          { iconComponent: TikTok, link: TIKTOK_LINK, name: 'Tiktok' },
        ],
      },
    ],
    [
      {
        type: 'external',
        title: 'Support',
        link: 'https://help.braid.co',
      },
      {
        type: 'external',
        title: 'Join Our Team',
        link: 'https://www.keyvalues.com/braid',
      },
      { type: 'internal', title: 'Terms of Service', link: '/legal/tos' },
      {
        type: 'internal',
        title: 'Privacy Policy',
        link: '/legal/privacy-policy',
      },
    ],
  ];

  const renderNavLists = () => {
    return navItems.map((n, i) => {
      const list = n.map((item, i) => renderNavItem(item, i));
      return (
        <ul className="nav-list" key={i}>
          {list}
        </ul>
      );
    });
  };

  const renderSocialIcons = (items) => {
    return items.map((item) => {
      const Comp = item.iconComponent;
      return (
        <li key={item.iconComponent}>
          <a aria-label={`Visit ${item.name}`} href={item.link} target="blank">
            <Comp />
          </a>
        </li>
      );
    });
  };

  const renderNavItem = (item, i) => {
    switch (item.type) {
      case 'internal':
        return (
          <li key={item.title}>
            <Link to={item.link} title={item.title}>
              {item.title}
            </Link>
          </li>
        );
      case 'external':
        return (
          <li key={item.title}>
            <a href={item.link} title={item.title} target="blank">
              {item.title}
            </a>
          </li>
        );
      case 'social':
        return (
          <li key={`social-${i}`}>
            <ul className="social-list">{renderSocialIcons(item.items)}</ul>
          </li>
        );
    }
  };

  const onClickFooterCta = (link) => {
    window && window.open(link, '_blank');
  };
  return (
    <Wrapper>
      <Container>
        <Inner>
          <div className="footer-logo">
            <LogoComponent />
          </div>

          <div className="footer-nav">
            <nav className="footer-nav-links">{renderNavLists()}</nav>
            <div className='footer-info-ctas'>
            {cta && (
                <div>
                  <button onClick={() => onClickFooterCta(cta.link)}>
                    {cta.text}
                  </button>
                </div>
              )}
              <div className="footer-nav-legal">
                Braid is not a bank. Banking services provided by Braid&apos;s
                partner bank.
              </div>

            
            </div>
          </div>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default Footer;
