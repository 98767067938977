import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const producthuntQuery = graphql`
  query {
    producthunt {
      post(slug: "braid-share-money-with-anyone") {
        id
        reviewsCount
        reviewsRating
      }
    }
  }
`;

export default function GlobalRichSnippets() {
  const {
    producthunt: {
      post: { reviewsCount, reviewsRating },
    },
  } = useStaticQuery(producthuntQuery);
  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'SoftwareApplication',
            name: 'Braid: Share Money',
            operatingSystem: 'iOS, Android',
            applicationCategory: 'Finance',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: String(reviewsRating),
              ratingCount: String(reviewsCount),
            },
            offers: {
              '@type': 'Offer',
              price: '0',
              priceCurrency: 'USD',
            },
          })}
        </script>
      </Helmet>
    </Fragment>
  );
}
