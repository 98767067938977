module.exports = (doc) => {
  if (doc.type === 'legal') return '/legal/' + doc.uid;
  if (doc.type === 'blog_post') return '/post/' + doc.uid;
  if (doc.type === 'faq') return '/faqs';
  if (doc.type === 'homepage') return '/';
  if (doc.type === 'groups') return '/groups';
  if (doc.type === 'how_to') return '/why-braid';
  if (doc.type === 'pov') return '/pov';
  if (doc.type === 'pool_money') return '/pool-money';
  if (doc.type === 'about') return '/about';
  if (doc.type === 'why_pool') return '/why-pool';
  return '/' + doc.uid;
};
