import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ParagraphOrHtml from './ParagraphOrHtml';

const SafetyAndSecuritySection = styled.section`
margin-bottom: 12.8rem;
${(props) => props.theme.mq.small`
    margin-bottom: 9.6rem;
`};
.list-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8rem;
    text-align: center;
    p {
    ${(props) => props.theme.font.p2}
    }

    ${(props) => props.theme.mq.large`
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 4rem;

    p {
        max-width: 48rem;
        margin: 0 auto;
    }
    `};
}
`

SafetyAndSecurity.propTypes = {
    data: PropTypes.shape({
        security_and_safety_title: PropTypes.string,
        security_and_safety_items: PropTypes.arrayOf(PropTypes.shape({
            image: PropTypes.shape({ alt: PropTypes.string, url: PropTypes.string }),
            body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        })),
    }),
};

export default function SafetyAndSecurity({ data: { security_and_safety_title, security_and_safety_items } }) {
    const filteredItems = security_and_safety_items?.filter(item => {
        const body = item?.body;
        const image = item?.image;
        return body?.html || image?.url;
    }) ?? [];
    if (!security_and_safety_title && !filteredItems?.length) {
        return null;
    }

    return (
        <SafetyAndSecuritySection className="safety-and-security-section">
            <div className="section-title">
                <h2>{security_and_safety_title}</h2>
            </div>
            <div className="list-items">{filteredItems.map(({ image, body }) => (
                <div className="section-row" key={image.url}>
                    <img alt={image.alt} src={image.url} />
                    <div className="section-image-content">
                    </div>
                    <div className="section-row-content">
                        <ParagraphOrHtml text={body} />
                    </div>
                </div>
            ))}
            </div>
        </SafetyAndSecuritySection>
    )

}