import { useState, useEffect } from 'react';
import { isBrowser } from '../utils/helpers';

export default function useWindowSize() {
  const INITIAL_STATE = {
    width: undefined,
    height: undefined,
  };

  if (!isBrowser) return INITIAL_STATE;

  const [windowSize, setWindowSize] = useState(INITIAL_STATE);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
