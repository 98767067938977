import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../consts/style';

const SVG = styled.svg`
  transition: all 0.3s ease;
  transform: rotate(0deg);

  ${(props) =>
    props.open &&
    `
  transform: rotate(180deg);
  `}
`;

Caret.propTypes = { fill: PropTypes.string, open: PropTypes.bool };

export default function Caret({ fill = colors.purple.primary, open, ...rest }) {
  return (
    <SVG
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      open={open}
      {...rest}
    >
      <path
        d="M8 14L20 26L32 14"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SVG>
  );
}
