import scrollToElement from 'scroll-to-element';
import MobileDetect from 'mobile-detect';

export const isBrowser = typeof window !== 'undefined';

export const scrollToTextInput = () =>
  scrollToElement('.phone-cta', { offset: -100 });

export const getMobileDeviceType = () => {
  if (!isBrowser) return null;
  const md = new MobileDetect(window.navigator.userAgent);
  const os = md.os();
  if (['iOS', 'iPadOS'].includes(os)) return 'apple';
  if (['AndroidOS'].includes(os)) return 'android';
  return null;
};

const searchEngines = [
  'google',
  'bing',
  'yahoo',
  'duckduckgo',
  'baidu',
  'aol',
  'ask',
  'excite',
  'wolfram',
];

const socials = [
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'tiktok',
  'pinterest',
  'snapchat',
];

export const getReferrerType = () => {
  if (!isBrowser) return null;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('gclid')) {
    return 'paid search';
  }

  if (document && document.referrer) {
    for (const searchEngine of searchEngines) {
      if (document.referrer.includes(searchEngine)) {
        return 'organic search';
      }
    }
    for (const social of socials) {
      if (document.referrer.includes(social)) {
        return 'social';
      }
    }
    return 'referral';
  }

  return 'direct';
};

export const getReferrerCampaign = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const campaign = urlParams.get('utm_campaign');
  return campaign ? campaign : null;
};
