import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../consts/style';

const SVG = styled.svg`
  transition: all 0.3s ease;
`;

Twitter.propTypes = { fill: PropTypes.string };

export default function Twitter({ fill = colors.elements.background }) {
  return (
    <SVG
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82341 4.65414C8.80932 4.26509 8.77056 3.90125 8.78465 3.54102C8.83399 2.12531 9.8101 0.749232 11.121 0.270124C12.594 -0.270222 13.9154 0.0107578 15.0642 1.09866C15.1417 1.1707 15.2052 1.19232 15.3109 1.1671C16.0192 1.005 16.6922 0.749232 17.3265 0.389001C17.3759 0.360182 17.4287 0.338568 17.5098 0.29534C17.2243 1.17791 16.6993 1.82992 15.9875 2.33785C16.6781 2.27301 17.3301 2.07488 17.9608 1.80471C17.9749 1.81912 17.9855 1.83353 17.9996 1.85154C17.7071 2.20457 17.4252 2.5684 17.1116 2.90341C16.8614 3.16998 16.5724 3.40053 16.2905 3.63828C16.2059 3.71033 16.1707 3.78238 16.1742 3.89405C16.2236 6.33281 15.554 8.55184 14.1868 10.5439C12.5869 12.8746 10.4056 14.3083 7.66053 14.8163C5.73297 15.1729 3.83712 15.0216 1.99413 14.3299C1.3105 14.0742 0.658583 13.7464 -0.00390625 13.3069C2.02585 13.4942 3.81598 12.9935 5.43344 11.7471C3.73845 11.5958 2.61081 10.7348 1.98356 9.1138C2.55443 9.18945 3.07949 9.19665 3.62921 9.03815C2.54738 8.76797 1.72984 8.16639 1.18364 7.20457C0.85592 6.6246 0.690298 5.9942 0.704394 5.29175C1.21888 5.56192 1.73689 5.72763 2.30424 5.73123C1.55013 5.13685 1.03211 4.38396 0.82773 3.43656C0.619821 2.48555 0.753728 1.57776 1.22945 0.706004C3.23102 3.10874 5.75764 4.4344 8.82341 4.65414Z"
        fill={fill}
        fillOpacity="0.95"
      />
    </SVG>
  );
}
