const APP_STORE_URL =
  'https://apps.apple.com/us/app/braid-share-money/id1450212254';
/**
 * Gets an App Store url with attribution parameters
 * set by a landing page template. This is used, because
 * sending a string up to the top-level header is a pain.
 */
let appStoreUrl = APP_STORE_URL;
export default function getAppStoreUrl() {
  return appStoreUrl;
}

export function setAppStoreUrl(url) {
  if (url) {
    appStoreUrl = url;
  }
}
