import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Container, Inner } from './Elements';
import Hamburger from './icons/Hamburger';
import Cancel from './icons/Cancel';

import LogoMark from './icons/LogoMark';
import LogoMarkCompact from './icons/LogoMarkCompact';

import { colors, font, mq, zIndex } from '../consts/style';
import { scrollToTextInput } from '../utils/helpers';

import { WAITLIST_URL } from '../consts/urls';

const Wrapper = styled.nav`
  height: 8.8rem;
  ${mq.medium`height: 8rem;`};
  ${mq.small`height: 7.2rem;`};
  position: fixed;
  z-index: 10000;
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${zIndex.header};
  background: ${colors.elements.background};
  color: ${colors.text.primary};

  transition: background 0.3s ease, height 0.3s ease, box-shadow 0.3s ease;
  box-shadow: ${(props) =>
    props.scrolled
      ? '0px 4px 25px rgba(0, 0, 0, 0.15);'
      : '0px 4px 25px rgba(0, 0, 0, 0);'};

  ${Container} {
    height: 100%;
  }

  ${Inner} {
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .nav-logo-desktop {
    ${mq.medium`display: none;`}
  }

  .nav-logo-mobile {
    display: none;
    ${mq.medium`display: flex;`}
  }

  .desktop-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .nav-item {
      margin-left: 4rem;
      a {
        ${font.p2};
        font-weight: 500;
        color: ${colors.purple.primary};
      }
      button {
        ${font.buttonMediumPrimary}
      }
    }

    ${mq.medium`display: none;`}
  }

  .mobile-nav {
    display: none;
    ${mq.medium`display: flex;`}

    button {
      padding: 0;
      background: transparent;
      border-radius: 0;
      border: none;
      width: 4rem;
    }
  }
`;

const MobileMenu = styled.nav`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  background: ${colors.purple.primary};
  color: ${colors.generics.white};
  z-index: ${zIndex.mobileMenu};

  padding-top: 2rem;
  ${mq.small`padding-top: 1.6rem;`}

  ${Container} {
    height: 100%;
  }

  ${Inner} {
    height: 100%;
    flex-direction: column;
  }

  .mobile-menu-cancel {
    width: 100%;
    text-align: right;
    margin-bottom: 4rem;
    button {
      padding: 0;
      border-radius: 0;
      background: transparent;
      border: none;
    }
  }

  .mobile-menu-items {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    a {
      color: ${colors.generics.white};
      opacity: 0.95;
      margin-bottom: 1.2rem;
      ${font.mobileNavLinks}

      &:hover {
        color: ${colors.text.purpleLight};
      }
    }
  }

  .mobile-menu-apps {
    display: flex;
    flex-direction: column;
    p {
      ${font.h3};
      opacity: 0.75;
      margin-bottom: 1.2rem;
    }
    a {
      margin-bottom: 1.2rem;
    }
  }

  ${mq.medium_up`display: none;`}
`;

Header.propTypes = {
  location: PropTypes.object.isRequired,
  light: PropTypes.bool.isRequired,
};

export default function Header({ location }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0 && !scrolled) setScrolled(true);
    else if (scrolled && currPos.y === 0) setScrolled(false);
  });

  const desktopNavItems = [
    {
      type: 'link',
      href: '/why-pool',
      title: 'Why Pool?',
    },
    {
      type: 'link',
      href: '/about',
      title: 'About Us',
    },
    {
      type: 'link',
      href: '/faqs',
      title: 'FAQs',
    },
    {
      type: 'button',
      title: 'Start a Pool',
      onClick: onDesktopCtaClick,
    },
  ];

  const mobileNavItems = [
    {
      href: '/why-pool',
      title: 'Why Pool?',
    },
    {
      href: '/about',
      title: 'About Us',
    },
    {
      href: '/faqs',
      title: 'FAQs',
    },
    // Remove after app returns to app store
    {
      href: WAITLIST_URL,
      title: 'Join the Waitlist',
    },
  ];

  function onDesktopCtaClick() {
    //open new window to external website typeform site
    window.open('https://form.typeform.com/to/xCWOoSPn', '_blank');
    setMenuOpen(false);
    scrollToTextInput();
  }

  function renderDesktopNavItems() {
    return desktopNavItems.map(({ title, href, type, onClick }) => {
      switch (type) {
        case 'link':
          return (
            <div className="nav-item" key={title.concat(href)}>
              <Link to={href} title={title}>
                {title}
              </Link>
            </div>
          );
        case 'button':
          return (
            <div className="nav-item" key={title.concat(href)}>
              <button onClick={onClick} title={title}>
                {title}
              </button>
            </div>
          );
      }
    });
  }

  function renderMobileNavItems() {
    return mobileNavItems.map(({ href, title }) => (
      <Link key={href} to={href} title={title}>
        {title}
      </Link>
    ));
  }
  return (
    <Fragment>
      <Wrapper scrolled={scrolled}>
        <Container>
          <Inner>
            <div className="nav-logo-desktop">
              <Link aria-label="Navigate to homepage" to="/">
                <LogoMark fill={colors.generics.black} />
              </Link>
            </div>
            <div className="nav-logo-mobile">
              <Link aria-label="Navigate to homepage" to="/">
                <LogoMarkCompact fill={colors.generics.black} />
              </Link>
            </div>
            <div className="desktop-nav">{renderDesktopNavItems()}</div>
            <div className="mobile-nav">
              <div className="mobile-nav-item">
                <button
                  aria-label="Open menu"
                  onClick={() => setMenuOpen(true)}
                >
                  <Hamburger fill={colors.purple.primary} />
                </button>
              </div>
            </div>
          </Inner>
        </Container>
      </Wrapper>
      {menuOpen && (
        <MobileMenu>
          <Container>
            <Inner>
              <div className="mobile-menu-cancel">
                <button
                  aria-label="Close menu"
                  onClick={() => setMenuOpen(false)}
                >
                  <Cancel fill={colors.generics.white} />
                </button>
              </div>
              <div className="mobile-menu-items">{renderMobileNavItems()}</div>
            </Inner>
          </Container>
        </MobileMenu>
      )}
    </Fragment>
  );
}
