import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG = styled.svg`
  transition: all 0.3s ease;
`;

LogoMark.propTypes = { fill: PropTypes.string };

export default function LogoMark({ fill = 'white' }) {
  return (
    <SVG
      width="115"
      height="32"
      viewBox="0 0 115 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.9178 5.85288L45.3725 4.26243C42.9617 1.78131 39.8091 0.508946 36.5947 0.508946H36.4711C34.6166 0.508946 32.7003 0.954274 30.9695 1.84493L27.0133 3.88072L15.3302 0L0 10.8787L10.1995 32H20.77L26.2097 28.3101L29.9804 30.1551C31.7113 31.0457 33.5657 31.4274 35.4202 31.4274C39.2527 31.4274 43.0235 29.5825 45.4343 26.2744L48.0923 22.5845C51.8631 17.5586 51.3686 10.3698 46.9178 5.85288ZM19.9045 29.1372H11.9304L3.64711 11.8966L15.8247 3.24453L23.6135 5.85288C20.2754 8.27038 18.2973 12.1511 18.2973 16.4135C18.2973 20.5487 20.2754 24.4294 23.428 26.8469L19.9045 29.1372ZM26.0243 25.002C23.0571 23.2207 21.1408 19.9125 21.1408 16.3499C21.1408 12.5328 23.1808 9.16103 26.5188 7.44334L27.3224 7.06163L29.1768 7.69781L33.2567 20.2306L26.0243 25.002ZM45.867 20.9304L43.2089 24.6203C41.4163 27.1014 38.511 28.6282 35.4202 28.6282C33.9366 28.6282 32.5149 28.3101 31.2167 27.6103L28.9914 26.4652L36.5947 21.3121L31.3404 5.34394L30.8459 5.15308L32.2058 4.45328C33.5657 3.75348 35.0493 3.37177 36.5329 3.37177C39.1291 3.37177 41.5399 4.38966 43.3944 6.29821L44.9398 7.88867C48.3396 11.3877 48.7105 16.9861 45.867 20.9304Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M69.1855 16.0168C70.5175 15.5432 71.5535 14.1224 71.5535 12.1688C71.5535 8.9128 69.1855 7.2256 65.3967 7.2256H59.0327V25.4H65.9887C69.7775 25.4 72.1455 23.5944 72.1455 20.1904C72.1455 18.2664 70.9023 16.5792 69.1855 16.0168ZM62.0223 14.8624V9.8896H65.3671C67.4687 9.8896 68.5935 10.6296 68.5935 12.3464C68.5935 14.0632 67.4687 14.8624 65.3671 14.8624H62.0223ZM62.0223 17.408H65.9295C68.0015 17.408 69.1263 18.4144 69.1263 20.072C69.1263 21.7 68.0015 22.736 65.9295 22.736H62.0223V17.408Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M81.907 12.1984C81.6998 12.08 81.1078 11.9616 80.4566 11.9616C79.1838 11.9616 78.0294 12.6424 77.5262 13.6192V12.2576H74.5662V25.4H77.5262V16.9048C77.8222 15.484 78.947 14.8032 80.1606 14.8032C80.7822 14.8032 81.4926 14.9512 81.907 15.2472V12.1984Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M93.7232 25.4V16.7864C93.7232 13.7968 91.7696 11.9616 88.4544 11.9616C85.7608 11.9616 83.6888 13.6488 83.304 15.8984H86.1456C86.4712 14.9808 87.2112 14.4776 88.3952 14.4776C90.0824 14.4776 90.9112 15.5136 90.9112 16.8456V17.9112C90.3784 17.5264 89.0464 17.112 87.8624 17.112C84.9616 17.112 82.7416 18.8584 82.7416 21.3448C82.7416 24.0384 84.9616 25.6368 87.6552 25.6368C89.1352 25.6368 90.4672 25.104 90.9112 24.66V25.4H93.7232ZM90.9112 21.9072C90.5856 22.736 89.4016 23.2392 88.2176 23.2392C86.8856 23.2392 85.4944 22.6768 85.4944 21.3448C85.4944 20.0424 86.8856 19.48 88.2176 19.48C89.4016 19.48 90.5856 19.9832 90.9112 20.812V21.9072Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M99.5828 12.2576H96.6228V25.4H99.5828V12.2576ZM99.938 8.2912C99.938 7.2848 99.0796 6.4856 98.1028 6.4856C97.126 6.4856 96.2676 7.2848 96.2676 8.2912C96.2676 9.2976 97.126 10.0968 98.1028 10.0968C99.0796 10.0968 99.938 9.2976 99.938 8.2912Z"
        fill={fill}
        fillOpacity="0.95"
      />
      <path
        d="M111.175 25.4H114.135V6.16H111.175V13.3824C110.583 12.6424 109.281 11.9616 107.83 11.9616C103.982 11.9616 101.733 15.0696 101.733 18.8288C101.733 22.6176 103.982 25.696 107.83 25.696C109.281 25.696 110.583 25.0152 111.175 24.2752V25.4ZM111.175 21.3152C110.672 22.3512 109.547 23.0616 108.334 23.0616C106.054 23.0616 104.693 21.256 104.693 18.8288C104.693 16.4016 106.054 14.596 108.334 14.596C109.547 14.596 110.672 15.3064 111.175 16.3424V21.3152Z"
        fill={fill}
        fillOpacity="0.95"
      />
    </SVG>
  );
}
